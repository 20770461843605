import React, {useState} from "react";
import axios, {AxiosRequestConfig} from "axios";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {themeSelect} from "../../../shared/components/Select";
import Select, {SingleValue} from "react-select";
import {ErrorToast} from "../../../shared/toasters/toasters";

export const ExportUser = () => {
  const { userStatus } = useSelector((state: RootState) => state.enum);
  const [status, setStatus] = useState("");
  const { t } = useTranslation();
  const exportList = [
    { label: "active", value: 1 },
    { label: "inactive", value: 0 },
    { label: "all", value: null },
  ];
  const downloadXLSFile = async () => {
    if (status === "" || status === undefined) {
      ErrorToast({
        response: {
          data: {
            code: 400,
            message: t("toasts.empty_status"),
            status: "error",
          },
        },
      });
    } else {
      const website = localStorage.getItem("website");
      const appVersion = localStorage.getItem("appVersion");
      const headers = {
        "Content-Type": "blob",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "app-version": appVersion,
        "Aw-Web-Site": website ?? "aw",
      };
      const config: AxiosRequestConfig = {
        method: "PATCH",
        url: `${
          process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_DEV_MODE
            : process.env.REACT_APP_PRO_MODE
        }admin/subscriber/download`,
        responseType: "arraybuffer",
        headers,
        params: {
          status: status,
        },
      };
      const response = await axios(config);
      const url = URL.createObjectURL(new Blob([(response as any)?.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };
  const handleSelectStatus = (
    selectedSingle: SingleValue<{ label: string; value: any }>
  ) => {
    console.log(selectedSingle?.value);

    if (selectedSingle?.value === undefined) {
      setStatus("");
    } else {
      setStatus(selectedSingle?.value as any);
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-center align-items-center">
          <div style={{ width: "200px" }} className="nesto me-2">
            <Select
              isClearable={true}
              theme={themeSelect}
              placeholder={t("global.status")}
              options={exportList?.map((item: any) => ({
                label: `${t(`global.${item?.label}`)}`,
                value: item?.value,
              }))}
              onChange={(e) => {
                handleSelectStatus(
                  e as SingleValue<{ label: string; value: string }>
                );
              }}
            />
          </div>
          <button
            className="btn btn-success ms-2"
            onClick={() => downloadXLSFile()}
          >
            {t("global.export")}
          </button>
        </div>
      </div>
    </div>
  );
};
