import { CreateForm } from "./CreateForm";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AdminTestEmailService } from "../service";
export const CreateTestEmail = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [state, setState] = useState<any>();
  const [image, setImage] = useState(undefined);
  const submitHandler = () => {
    AdminTestEmailService.create(state)
      .then((response) => {
        SuccessToast(t(`test_email.successfully_created`));
        navigate("/admin/newsletter-email-test");
        setTriggerUpdate(!triggerUpdate);
        setState({});
      })
      .catch((error) => ErrorToast(error));
  };
  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler();
        }}
      >
        <CreateForm state={state} setState={setState} />
      </Form>
    </div>
  );
};
