import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {CreateForm} from "./CreateForm";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {useTranslation} from "react-i18next";
import {multipartFormData} from "../../../helpers/api.services";
import {USERS_URL} from "../../../helpers/api.routes";
import {AdminUserService} from "../service";

export const UpdateUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState<any>();
  const [image, setImage] = useState(undefined);
  const [password, setPassword] = useState("");
  const changePassword = () => {
    AdminUserService.changePassword(id, { password }).then((res) => {
      navigate("/admin/subscriber");
    });
  };

  const submitHandler = (event: React.FormEvent, id?: number) => {
    if (state?.id) {
      multipartFormData(
        image,
        `${USERS_URL}/${state?.id}`,
        JSON.stringify({
          ...state,
        }),
        "post"
      )
        .then((response) => {
          SuccessToast(t(`subscriber.successfully_updated`));
          navigate("/admin/subscriber");
        })
        .catch((error) => ErrorToast(error));
    }
  };

  return (
    <div className="col-12">
      <CreateForm
        state={state}
        setState={setState}
        setPassword={setPassword}
        changePassword={changePassword}
        submitHandler={submitHandler}
      />
    </div>
  );
};
