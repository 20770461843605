import {Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import React, {useState} from "react";

import {AdminNewsletterEmailService} from "../service";

import {useTranslation} from "react-i18next";
import {ErrorToast, SuccessToast} from "../../../../shared/toasters/toasters";
import {Loader} from "../../../../shared/components/Loader";
import {EmptyState} from "../../../../shared/components/EmptyState";
import {ConfirmDelete} from "../../../../shared/components/ConfirmDelete";
import {Pagination} from "../../../../shared/paginations/Paginations";

export const List = ({
  list,
  totalPages,
  page,
  handlePages,
  setTriggerUpdate,
  editHandler,
  active,
  totalItems
}: any) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [selectedPrompt, setselectedPrompt] = useState<any>();
  const deleteHandler = () => {
    selectedPrompt?.id &&
      AdminNewsletterEmailService.delete(selectedPrompt?.id)
        .then((response) => {
          if (response?.status === "success") {
            SuccessToast(t(`newsletter_email.successfully_deleted`));
            setTriggerUpdate((prev: boolean) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };

  const changeStatus = (id: any) => {
    AdminNewsletterEmailService.changeStatus(id)
      .then((response) => {
        if (response?.status === "success") {
          SuccessToast(t(`newsletter_email.successfully_deleted`));
          setTriggerUpdate((prev: boolean) => !prev);
        }
      })
      .catch((error) => ErrorToast(error));
  };

  return (
    <div className="card">
      <div className="card-header pb-0">
        <div className="d-flex justify-content-between">
          <h4 className="card-title mg-b-0">
            {t("newsletter_email.listOfNewsletterEmails")}
          </h4>
          <h4 className="card-title mg-b-0">
            {t("global.total" )}: {totalItems}
          </h4>
        </div>
      </div>
      <div className="card-body">
        {list === undefined ? (
          <Loader />
        ) : list.length === 0 ? (
          <EmptyState />
        ) : (
          <div className="table-responsive">
            <Table className="align-middle table-nowrap mb-0">
              <thead className="table-light text-center">
                <tr className="">
                  <th scope="col">{t(`table_headers.id`)}</th>
                  <th scope="col">{t(`global.firstName`)}</th>
                  <th scope="col">{t(`global.lastName`)}</th>
                  <th scope="col">{t(`table_headers.greeting`)}</th>
                  <th scope="col">{t(`global.email`)}</th>
                  <th scope="col">{t(`global.status`)}</th>
                  <th scope="col">{t(`table_headers.actions`)}</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {list?.map((prompt: any) => {
                  return (
                    <tr key={prompt?.id} className="">
                      <td>
                        <Link to="#" className="fw-medium">
                          #{prompt?.id}
                        </Link>
                      </td>
                      <td>{prompt?.firstName ?? "-"}</td>
                      <td>{prompt?.lastName ?? "-"}</td>
                      <td>
                        {prompt?.greeting
                          ? `${t(`greeting.${prompt?.greeting?.toLowerCase()}`)}`
                          : "-"}
                      </td>
                      <td>{prompt?.email ?? "-"}</td>
                      <td>
                        {prompt?.active ? (
                          <span className="text-success">
                            {t("global.active")}
                          </span>
                        ) : (
                          <span className="text-danger">
                            {" "}
                            {t("global.deactivated")}
                          </span>
                        )}
                      </td>

                      <td>
                        <div className="d-flex align-items-center justify-content-center">
                          <button
                            className="btn btn-sm btn-warning d-flex align-items-center me-2"
                            onClick={() => editHandler(prompt?.id)}
                          >
                            <i className="ri-edit-2-fill me-1" />
                            {t("global.edit")}
                          </button>
                          <button
                            className="btn btn-sm btn-danger  d-flex align-items-center"
                            onClick={() => {
                              handleShow();
                              setselectedPrompt(prompt);
                            }}
                          >
                            <i className="ri-delete-bin-fill me-1" />
                            {t("global.delete")}
                          </button>
                          <button
                            onClick={() => changeStatus(prompt?.id)}
                            className={`btn ms-2 btn-sm btn-${
                              active ? "danger" : "success"
                            } d-flex align-items-center`}
                          >
                            {active
                              ? t("global.deactivate")
                              : t("global.activate")}
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}

        <div className="d-flex justify-content-end ">
          {totalPages <= 1 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
        <ConfirmDelete
          show={show}
          setShow={setShow}
          itemName={selectedPrompt?.email}
          deleteHandler={deleteHandler}
          product={selectedPrompt}
          selectedProduct={selectedPrompt}
        />
      </div>
    </div>
  );
};
