import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { AdminMagazineCategoryService } from "../service";
import { ErrorToast } from "../../../shared/toasters/toasters";
// import { InitEditor } from "../../tests/components/CreateForm";
import { InitEditorMoreFields } from "../../model/components/CreateForm";
export const CreateForm = ({
  setIsMenuOpened,
  state,
  setState,
  currentId,
}: any) => {
  const { t } = useTranslation();

  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };

  const switchHandler = (e: any) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  const handleText = (text: string) => {
    setState((prev: any) => ({ ...prev, text }));
  };
  const handleTextFooter = (textFooter: string) => {
    setState((prev: any) => ({ ...prev, textFooter }));
  };
  useEffect(() => {
    if (!!Number(currentId)) {
      AdminMagazineCategoryService.getSingle(currentId)
        .then((response) => {
          const { data } = response;
          setState(data);
          InitEditorMoreFields(data?.text ?? "", handleText, ".text");
          InitEditorMoreFields(
            data?.textFooter ?? "",
            handleTextFooter,
            ".textFooter"
          );
        })
        .catch((error) => ErrorToast(error));
    } else {
      InitEditorMoreFields(undefined, handleText, ".text");
      InitEditorMoreFields(undefined, handleTextFooter, ".textFooter");
    }
  }, [currentId]);
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={6}>
            <label htmlFor="name" className="required-field">
              {t("global.name")}
            </label>
            <input
              type="text"
              name="name"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.name}
            />
          </Col>
          <Col md={3} className="">
            <div className="form-check form-switch p-0">
              <label
                className="d-block text-start"
                htmlFor="flexSwitchCheckDefault"
              >
                {t("global.showOnShelf")}
              </label>
              <div className="row">
                <input
                  className="form-check-input switch-custom ms-4 mt-2"
                  type="checkbox"
                  name="showOnShelf"
                  checked={state?.showOnShelf}
                  onChange={switchHandler}
                  role="switch"
                  id="flexSwitchCheckDefault"
                />
              </div>
            </div>
          </Col>
          <Col md={12} className="my-2">
            <label htmlFor="title" className="required-field">
              {t("global.text")}
            </label>
            <textarea className="editor text"></textarea>
          </Col>
          <Col md={12} className="mb-3" id="custom-editor">
            <div>
              <label htmlFor="title" className="required-field">
                {t("global.textFooter")}
              </label>
            </div>
            <textarea className="editor textFooter"></textarea>
          </Col>
        </Row>
        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              onClick={() => {
                setIsMenuOpened(false);
                setState({} as any);
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `  ${t("global.update")}` : `${t("global.create")}`}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
