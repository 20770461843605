import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ADMIN_URL } from "../../../helpers/api.routes";
import { multipartFormData } from "../../../helpers/api.services";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { AdminAdminService } from "../service";
import { CreateForm } from "./CreateForm";
import {authSlice} from "../../../store/slices/auth.slice";
import {useDispatch, useSelector} from "react-redux";

export const UpdateAdmin = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [state, setState] = useState<any>();
  const [activeTab, setActiveTab] = useState("aw");
  const [image, setImage] = useState<File>();
  const [document, setDocument] = useState<File>();
  const getAuth = useSelector((state: any) => state.auth);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const changePassword = (e: any) => {
    e.stopPropagation();
    AdminAdminService.changePassword(id, { password }).then((res) => {
      navigate("/admin");
    });
  };
  const submitHandler = (event: React.FormEvent, id?: number) => {
    multipartFormData(
      image,
      `${ADMIN_URL}/${id}`,
      JSON.stringify({ ...state, website: activeTab }),
      "post"
    )
      .then((response) => {
          const {data} = response?.data;
          if(data?.id === getAuth?.id){
              dispatch(authSlice.actions.setAuth(data));
          }
        SuccessToast(t(`admin.successfully_updated`));
        // setIsMenuOpened(false);
        // setTriggerUpdate(!triggerUpdate);
        setState({});
        navigate("/admin");
      })
      .catch((error) => ErrorToast(error));
  };
  return (
    <div className="col-12">
      <CreateForm
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        image={image}
        document={document}
        setImage={setImage}
        setDocument={setDocument}
        state={state}
        setState={setState}
        setPassword={setPassword}
        changePassword={changePassword}
        submitHandler={submitHandler}
      />
    </div>
  );
};
