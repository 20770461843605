import React, { useEffect, useState } from "react";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import { AdminNewsService } from "../news/service";
import { AdminEditorChoiceService } from "./service";
import { List } from "./components/List";
import { Pagination } from "../../shared/paginations/Paginations";

export const AdminEditorChoice = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState<any>();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const domain = useSelector((state: any) => state.company.clientDomain);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 20,
    term: "",
  });
  const [list, setList] = useState([]);
  const handleShow = () => setShow(true);
  const editHandler = (id: number): void => {
    navigate(`/admin/news/update/${id}`);
  };

  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const deleteHandler = () => {
    selectedPrompt?.id &&
      AdminEditorChoiceService.update(selectedPrompt?.id)
        .then((response) => {
          if (response?.status === "success") {
            SuccessToast(t(`news.successfully_deleted`));
            setTriggerUpdate((prev: boolean) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  const updatePosition = (newList: any) => {
    const newListUpdated = newList?.map((item: any) => item.id);
    AdminEditorChoiceService.updatePosition({ ids: newListUpdated });
  };
  useEffect(() => {
    AdminEditorChoiceService.getAll(pagination)
      .then((response) => {
        const { data } = response;
        // data?.length === 0 && setPage(0);
        setList(data);
        setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);
  return (
    <>
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body d-flex align-items-center">
            <div className="search-box">
              <input
                type="text"
                className="form-control"
                id="searchMemberList"
                onChange={(e: any) =>
                  setPagination((prev: any) => ({
                    ...prev,
                    page: 1,
                    term: e.target.value,
                  }))
                }
                placeholder={t("global.search")}
              />
              <i className="ri-search-line search-icon"></i>
            </div>

            <div className="col-sm-auto ms-auto">
              <div className="list-grid-nav hstack gap-1">
                <button
                  className="btn btn-info"
                  onClick={() => navigate("/admin/news/create")}
                >
                  {t("global.add")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {page > 1 || (pagination as any)?.term !== "" ? (
        <>
          <div className="col-12">
            <List
              page={page}
              handlePages={handlePages}
              totalPages={totalPages}
              list={list}
              setTriggerUpdate={setTriggerUpdate}
              editHandler={editHandler}
              domain={domain}
            />
          </div>{" "}
        </>
      ) : (
        <>
          <div className="col-12">
            <DraggableBootstrapTable
              preview={true}
              list={list}
              setList={setList}
              headers={["id", "title", "isActive"]}
              title="editor_choice"
              onEdit={editHandler}
              selectedPrompt={selectedPrompt}
              setSelectedPrompt={setSelectedPrompt}
              handleShow={handleShow}
              show={show}
              setShow={setShow}
              deleteHandler={deleteHandler}
              updatePosition={updatePosition}
            />
          </div>
        </>
      )}

      {/* {page && (
        <div className="d-flex justify-content-end ">
          {totalPages <= 1 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
      )} */}
    </>
  );
};
