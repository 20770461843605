import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { List } from "./components/List";
import { AdminEventService } from "./service";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import { useSelector } from "react-redux";
import { Pagination } from "../../shared/paginations/Paginations";
import { AdminFeaturedArticlesService } from "../featuredArticles/service";
import Select, { SingleValue } from "react-select";
import { AdminEventCategoryService } from "../eventCategory/service";

export const AdminEvent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const domain = useSelector((state: any) => state.company.clientDomain);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState<any>();
  const [categoryList, setCategoryList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    term: "",
    category: null,
  });
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [list, setList] = useState(undefined);
  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const mappedData = categoryList?.map((position: any) => ({
    label: position.name,
    value: position.id,
  }));
  const editHandler = (id: number) => {
    navigate(`/admin/event/update/${id}`);
  };
  const handleSelectCategory = (
    selectedSingle: SingleValue<{ label: string; value: number }>
  ) => {
    if (selectedSingle?.value as number) {
      setPagination((prev: any) => ({
        ...prev,
        category: selectedSingle?.value,
        page: 1,
      }));
    } else {
      setPagination((prev: any) => ({
        ...prev,
        category: null,
        page: 1,
      }));
    }
  };
  useEffect(() => {
    AdminEventService.getAll(pagination)
      .then((response) => {
        const { data } = response;
        setList(data?.items);
        setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);
  const updatePosition = (newList: any) => {
    const newListUpdated = newList?.map((item: any) => item.id);
    AdminEventService.updatePosition({ ids: newListUpdated });
  };
  useEffect(() => {
    AdminEventCategoryService.getAll({ perPage: 0, page: 1 })
      .then((response) => {
        const { data } = response;
        setCategoryList(data?.items);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  const deleteHandler = () => {
    selectedPrompt?.id &&
      AdminEventService.delete(selectedPrompt?.id)
        .then((response) => {
          if (response?.status === "success") {
            SuccessToast(t(`event.successfully_deleted`));
            setTriggerUpdate((prev: boolean) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-body d-flex align-items-center ">
            <div className="d-flex align-items-center gap-3">
              <div className="search-box">
                <input
                  type="text"
                  className="form-control"
                  id="searchMemberList"
                  onChange={(e: any) =>
                    setPagination((prev: any) => ({
                      ...prev,
                      term: e.target.value,
                      page: 1,
                    }))
                  }
                  placeholder={t("global.search")}
                />
                <i className="ri-search-line search-icon"></i>
              </div>
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    minWidth: "190px",
                  }),
                }}
                className="react-select-field"
                isClearable
                onChange={(e) => {
                  handleSelectCategory(
                    e as SingleValue<{ label: string; value: number }>
                  );
                }}
                options={mappedData}
                isSearchable={false}
                placeholder={t("global.choose_category")}
                required
              />
            </div>
            <button
              className="btn btn-primary ms-auto"
              onClick={() => navigate("/admin/event/create")}
            >
              {t("global.add")}
            </button>
          </div>
        </div>
      </div>
      {page > 1 || pagination?.term || pagination?.category ? (
        <>
          <div className="col-12">
            <List
              page={page}
              handlePages={handlePages}
              totalPages={totalPages}
              list={list!}
              setTriggerUpdate={setTriggerUpdate}
              editHandler={editHandler}
              // domain={domain}
            />
          </div>{" "}
        </>
      ) : (
        <>
          <div className="col-12">
            <DraggableBootstrapTable
              isDraggable={!!(pagination as any)?.category}
              preview={true}
              list={list!}
              setList={setList}
              headers={["id", "title", "category", "isActive"]}
              title="event"
              onEdit={editHandler}
              selectedPrompt={selectedPrompt}
              setSelectedPrompt={setSelectedPrompt}
              handleShow={handleShow}
              show={show}
              setShow={setShow}
              deleteHandler={deleteHandler}
              updatePosition={updatePosition}
            />
          </div>
        </>
      )}
      {page && (
        <div className="d-flex justify-content-end ">
          {totalPages <= 1 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
      )}
    </>
  );
};
