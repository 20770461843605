import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import { AdminGalleryService } from "../service";
import DatePicker from "react-datepicker";
import { AdminVideoService } from "../../../galleryVideo/service";
import { AdminAlbumService } from "../../../album/service";
import { useRef } from "react";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { CustomImageHandler } from "../../../../shared/components/CustomImageHandler";
import Cropper, { ReactCropperElement } from "react-cropper";
import { nanoid } from "nanoid";
import { EducationContinuingService } from "../../service";
import { PICTURE_URL } from "../../../../helpers/api.routes";

export const CreateForm = ({ state, setDocument, setImage, setState }: any) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  const fileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.id === "documentFile") {
      setDocument(e.target.files![0]);
    } else {
      setImage(e.target.files![0]);
    }
  };
  const switchHandler = (e: any) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  useEffect(() => {
    if (!!Number(id)) {
      EducationContinuingService.getSingle(id as unknown as number)
        .then((response) => {
          const { data } = response;
          setState(data);
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error));
    }
  }, [id]);
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={6} className="mt-2">
            <label>{t("global.title")}</label>
            <input
              name="title"
              type="text"
              className="form-control"
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.title}
            />
          </Col>
          <Col md={3} className=" mt-2">
            <label
              className="d-block text-start"
              htmlFor="flexSwitchCheckDefault"
            >
              {t("global.active")}
            </label>
            <div className="form-check form-switch">
              <input
                className="form-check-input switch-custom"
                type="checkbox"
                name="isActive"
                checked={state?.isActive}
                onChange={switchHandler}
                role="switch"
                id="flexSwitchCheckDefault"
              />
            </div>
          </Col>
          <Row>
            <Col md={6}>
              <div className="">
                <label htmlFor="documentFile">{t("download.document")}</label>
                <input
                  onChange={fileHandler}
                  className="form-control"
                  type="file"
                  id="documentFile"
                  accept="application/*"
                />
              </div>
              {state?.file && (
                <div className="d-flex align-items-center mt-3">
                  <i className="bi bi-file-earmark display-3"></i>
                  <a
                    target="_blank"
                    download
                    href={PICTURE_URL + state.file.path}
                  >
                    {state.file.name}
                  </a>
                </div>
              )}
            </Col>
            <Col md={6}>
              <div className="">
                <label htmlFor="imageFile">{t("download.image")}</label>
                <input
                  onChange={fileHandler}
                  className="form-control"
                  type="file"
                  accept="image/*"
                  id="imageFile"
                />
              </div>

              {state?.image && (
                <div className="col-md-6 py-3">
                  <img
                    className="rounded"
                    alt="200x200"
                    width={200}
                    src={PICTURE_URL + state?.image.path}
                    data-holder-rendered="true"
                  />
                </div>
              )}
            </Col>
          </Row>
        </Row>
        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              onClick={() => {
                navigate("/admin/education-continuing");
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {t("global.save")}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
