import { CreateForm } from "./CreateForm";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  DocImgFormData,
  multipartFormData,
} from "../../../helpers/api.services";
import { ALTERNATIVE_DRIVES_URL } from "../../../helpers/api.routes";
import { AdminAlternativeDrivesCategoryService } from "../../alternativeDrivesCategory/service";

export const CreateAlternativeDrives = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [state, setState] = useState<any>();
  const [image, setImage] = useState<File>();
  const [document, setDocument] = useState<File>();
  const [categories, setCategories] = useState();
  const submitHandler = () => {
    DocImgFormData(
      document ?? state?.document,
      image ?? state?.image,
      `${ALTERNATIVE_DRIVES_URL}`,
      JSON.stringify({
        ...state,
        isActive: state?.isActive ?? false,
      }),
      "post"
    )
      .then((response) => {
        SuccessToast(t(`event.successfully_created`));

        setTriggerUpdate(!triggerUpdate);

        navigate("/admin/alternative-drives");
      })
      .catch((error) => ErrorToast(error));
  };
  useEffect(() => {
    AdminAlternativeDrivesCategoryService.getAll({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setCategories(data?.items);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler();
        }}
      >
        <CreateForm
          state={state}
          setImage={setImage}
          setDocument={setDocument}
          setState={setState}
          categories={categories}
        />
      </Form>
    </div>
  );
};
