import React, {useCallback, useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {useParams} from "react-router-dom";
import {AdminGalleryService} from "../service";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import SortableList, {SortableItem} from "react-easy-sort";
import {arrayMoveImmutable} from "array-move";
import ImageViewer from "react-simple-image-viewer";
import {ConfirmDelete} from "../../../shared/components/ConfirmDelete";

export interface IPhoto {
  src: string;
  width: number;
  height: number;
}

export const Gallery = ({
  setIsMenuOpened,

  fileHandler,
}: any) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const currentTheme = useSelector((state: RootState) => state.theme);
  const [albums, setAlbums] = useState<any[]>([]);
  const [state, setState] = useState<any[]>();
  const [newIds, setNewIds] = useState<any[]>([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [selectedPrompt, setSelectedPrompt] = useState<any>();
  const openImageViewer = useCallback((index: any) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  const onSortEnd = (oldIndex: number, newIndex: number) => {
    updatePosition(arrayMoveImmutable(state as any, oldIndex, newIndex));
    setState((array: any) => arrayMoveImmutable(array, oldIndex, newIndex));
  };
  const updatePosition = (newIds: number[]) => {
    const newArray = newIds?.map((item: any) => item?.id);
    AdminGalleryService.updatePosition({ ids: newArray });
  };
  useEffect(() => {
    if (!!Number(id)) {
      AdminGalleryService.getSingle(id as unknown as number)
        .then((response) => {
          const { data } = response;
          const mapped = data?.data?.map((doc: any) => {
            return {
              id: doc.image?.id,
              path: doc.image.path,
            };
          });

          setState(mapped);
        })
        .catch((error) => ErrorToast(error));
    }
  }, [id]);

  const deleteHandler = (selectedPrompt: any) => {
    selectedPrompt?.id &&
    AdminGalleryService.delete(selectedPrompt?.id)
        .then((response) => {
          if (response?.status === "success") {
            setState((prev: any) =>
                prev.filter((item: any) => item.id !== selectedPrompt.id)
            );
            SuccessToast(t(`gallery.successfully_deleted`));
            setTriggerUpdate((prev: boolean) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };

  return (
    <Card>
      <div>
        <SortableList
          onSortEnd={onSortEnd}
          className="list"
          draggedItemClassName="dragged"
        >
          {state?.map((item, index) => (
              <div className={'d-inline-block position-relative'}>
                <SortableItem key={item?.id}>
                  <img
                      src={item?.path}
                      className="object-fit-cover cursor-move"
                      onDoubleClick={() => openImageViewer(index)}
                      key={item?.id}
                      width={250}
                      height={250}
                      style={{margin: "2px"}}
                      alt=""
                  />
                  {/* <div className="item">{item}</div> */}
                </SortableItem>
                <i onClick={() => {
                  handleShow();
                  setSelectedPrompt(item);
                }}
                   className={'ri-delete-bin-fill bg-danger text-white delete-icon-position'}></i>
              </div>
          ))}
        </SortableList>
        {isViewerOpen && (
          <ImageViewer
            src={state!?.map((img: any) => img?.path as unknown as string)}
            backgroundStyle={{ zIndex: 10000 }}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
      </div>
      <ConfirmDelete
          show={show}
          setShow={setShow}
          itemName={selectedPrompt?.title}
          deleteHandler={deleteHandler}
          product={selectedPrompt}
          selectedProduct={selectedPrompt}
      />
    </Card>
  );
};
