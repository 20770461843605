import { useEffect, useRef, useState } from "react";
// import { N1ED } from "@edsdk/n1ed-react";
import { Card, Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import { Editor } from "tinymce";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { RootState } from "../../../store";
import { AdminAlbumService } from "../service";
import "react-datepicker/dist/react-datepicker.css";
import { ETheme } from "../../../store/slices/theme.slice";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import { editorConfigurationCustom } from "../../../shared/functions/editorConfigurationCustom";
import { InitEditor } from "../../tests/components/CreateForm";
// @ts-ignore
// import Editor from "ckeditor5-custom-build/build/ckeditor";
export const CreateForm = ({ setIsMenuOpened, state, setState }: any) => {
  const { id } = useParams();
  const firstLoad = useRef(true);
  const { t } = useTranslation();
  const [selectEditor, setSelectEditor] = useState<boolean>(false);
  const currentTheme = useSelector((state: RootState) => state.theme);
  const navigate = useNavigate();
  const isDarkMode: boolean = (currentTheme as string) === ETheme.dark;
  const [editorData, setEditorData] = useState<any>();
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };

  const switchHandler = (e: any) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  const handleText = (description: string) => {
    setState((prev: any) => ({ ...prev, description }));
  };
  useEffect(() => {
    if (!!Number(id)) {
      AdminAlbumService.getSingle(id as unknown as number)
        .then((response) => {
          const { data } = response;
          setState({ ...data, category: data?.category?.id });
          InitEditor(data?.description, handleText);
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error));
    } else {
      InitEditor(undefined, handleText);
    }
  }, [id]);

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={6}>
            <label htmlFor="title" className="required-field">
              {t("global.title")}
            </label>
            <input
              type="text"
              name="title"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.title}
            />
          </Col>
          <Col md={6}>
            <label htmlFor="subtitle" className="required-field">
              {t("global.date")}
            </label>
            <div className="datepicker-container">
              <DatePicker
                className="date-picker"
                dateFormat="dd/MM/yyyy"
                selected={state?.date && new Date(state.date)}
                onChange={(date) =>
                  setState((prev: any) => ({ ...prev, date: date }))
                }
                isClearable
                placeholderText="Choose date..."
              />
            </div>
          </Col>
        </Row>
        <Row className="my-3">
          <Col md={12}>
            <div
              className={`row my-2 ${isDarkMode ? "darkEditor" : "1"}`}
              key={isDarkMode ? 1 : 0}
            >
              <label className="d-block text-start" htmlFor="content">
                {t("global.description")}
              </label>

              <textarea className="editor" onChange={console.log}></textarea>
              {/* <N1ED
                id={`description`}
                onFocus={(e) => setSelectEditor(true)}
                apiKey={process.env.REACT_APP_EDITOR_KEY}
                initialValue={
                  editorData ? editorData : state ? state?.description : ""
                }
                onEditorChange={changeEditorHandler}
              /> */}
              {/* <CKEditor
                config={editorConfigurationCustom}
                editor={Editor}
                data={state?.description ?? ""}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  changeHandlerEditor(data, "description");
                }}
              /> */}
            </div>
          </Col>

          <Col md={4} className="">
            <div className="form-check form-switch p-0">
              <label
                className="d-block text-start"
                htmlFor="flexSwitchCheckDefault"
              >
                {t("global.active")}
              </label>
              <div className="row">
                <input
                  className="form-check-input switch-custom ms-4 mt-2"
                  type="checkbox"
                  name="isActive"
                  checked={state?.isActive}
                  onChange={switchHandler}
                  role="switch"
                  id="flexSwitchCheckDefault"
                />
              </div>
            </div>
          </Col>
        </Row>
        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              onClick={() => {
                navigate("/admin/album");
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `  ${t("global.update")}` : `${t("global.create")}`}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
