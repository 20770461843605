import React from "react";
import { TopHeader } from "./TopHeader";
import { MainHeader } from "./MainHeader";
import { SideBar } from "./SideBar";
import { useLocation } from "react-router-dom";
import { Confirm505Err } from "../components/Confirm505Err";

interface iPageTemplate {
  title?: string;
  children?: React.ReactElement;
}

export function PageTemplate({ title, children }: iPageTemplate) {
  const location = useLocation();

  const body = document?.querySelector("body");
  const closeMenu = () => {
    body!.classList.remove("vertical-sidebar-enable");
  };

  const isFluid =
    location.pathname === "/admin/news/create" ||
    location.pathname === "/admin/tests/create" ||
    location.pathname.includes("admin/news/update/") ||
    location.pathname.includes("admin/tests/update/");

  return (
    <>
      <Confirm505Err />
      <div id="layout-wrapper">
        <TopHeader />
        <MainHeader />
        <SideBar />
        <div className="vertical-overlay" onClick={closeMenu}></div>
        <div className="main-content">
          <div className="page-content">
            <div className={`container${isFluid ? "-fluid" : ""}`}>
              {/* <!-- start page title --> */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">{title}</h4>

                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <a href="#">Pages</a>
                        </li>
                        <li className="breadcrumb-item active">{title}</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end page title --> */}
              <div className="row">{children}</div>
            </div>
            {/* <!-- container-fluid --> */}
          </div>
        </div>
      </div>
    </>
  );
}
