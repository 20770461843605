import { useEffect, useState } from "react";
import { Card, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { PICTURE_URL } from "../../../helpers/api.routes";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { RootState } from "../../../store";
import { AdminSettingsService } from "../../globalSettings/service";
import { AdminAdminService } from "../service";

export const CreateForm = ({
  activeTab,
  setActiveTab,
  checkedValue,
  setCheckedValue,
  state,
  setDocument,
  submitHandler,
  setImage,
  setState,
  setPassword,
  changePassword,
}: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { website } = useSelector((state: RootState) => state.enum);
  // const [activeTab, setActiveTab] = useState("aw");
  const [websitModules, setWebsitModules] = useState({});
  // const [checkedValue, setCheckedValue] = useState([] as any);
  const [visibilityPassword, setVisibility] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };

  const switchHandler2 = (e: any) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  const fileHandler = (e: any) => {
    if (e.target.id === "documentFile") {
      setDocument(e.target.files[0]);
    } else {
      setImage(e.target.files[0]);
    }
  };
  const changeActiveTab = (tab: string) => {
    setActiveTab(tab);
    if (!id) {
      setCheckedValue([]);
    }
  };

  const switchHandler = (e: any) => {
    if (id) {
      let arr = state?.administrationData?.[activeTab] || [];

      if (e.target.checked) {
        setState((prev: any) => ({
          ...prev,
          administrationData: {
            ...prev?.administrationData,
            [activeTab]: [...arr, e.target.name],
          },
        }));
      } else {
        const newArr = arr.filter((item: string) => item !== e.target.name);
        setState((prev: any) => ({
          ...prev,
          administrationData: {
            ...prev?.administrationData,
            [activeTab]: newArr,
          },
        }));
      }
    } else {
      let arr = checkedValue;
      if (e.target.checked) {
        setCheckedValue((prev: any) => [...prev, e.target.name]);
      } else {
        const newArr = arr.filter((item: string) => item !== e.target.name);
        setCheckedValue(newArr);
      }
    }
  };
  useEffect(() => {
    if (!!Number(id)) {
      AdminAdminService.getSingle(id as unknown as number)
        .then((response) => {
          const { data } = response;
          setState(data);
        })
        .catch((error) => ErrorToast(error));
    }
  }, [id]);
  useEffect(() => {
    AdminSettingsService.getWebsiteModules()
      .then((response) => {
        const { data } = response;
        setWebsitModules(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);

  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            {t("global.user")}
          </button>
        </li>
        {id && (
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
            >
              {t("global.password")}
            </button>
          </li>
        )}
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <Card>
            <Card.Body>
              <div className="row">
                <div className={`col-md-${id ? 4 : 6} my-2`}>
                  <label htmlFor="username" className="required-field">
                    {t("global.username")}
                  </label>
                  <input
                    type="text"
                    name="username"
                    required
                    value={state?.username ?? ""}
                    onChange={changeHandler}
                    className="form-control"
                  />
                </div>
                {!id && (
                  <div className="col-md-6 my-2">
                    <label className="" htmlFor="password-input">
                      {t("login.password")}
                    </label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                      <input
                        name="password"
                        onChange={changeHandler}
                        type={`${visibilityPassword ? "text" : "password"}`}
                        className="form-control pe-5"
                        // placeholder="Enter password"
                        id="password-input"
                      />
                      <button
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                        type="button"
                        id="password-addon"
                        onClick={() => setVisibility((prev: boolean) => !prev)}
                      >
                        <i
                          className={`${
                            visibilityPassword
                              ? "ri-eye-line"
                              : "ri-eye-off-line"
                          }`}
                        />
                      </button>
                    </div>
                  </div>
                )}

                <div className={`col-md-${id ? 4 : 6} my-2`}>
                  <label htmlFor="firstName" className="required-field">
                    {t("users.firstName")}
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    required
                    value={state?.firstName ?? ""}
                    // placeholder={t("users.firstName")}
                    onChange={changeHandler}
                    className="form-control"
                  />
                </div>
                <div className={`col-md-${id ? 4 : 6} my-2`}>
                  <label htmlFor="lastName">{t("users.lastName")}</label>
                  <input
                    type="text"
                    name="lastName"
                    value={state?.lastName ?? ""}
                    onChange={changeHandler}
                    // placeholder={t("users.lastName")}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 my-2">
                  <label htmlFor="email" className="required-field">
                    {t("users.email")}
                  </label>
                  <input
                    type="text"
                    name="email"
                    value={state?.email ?? ""}
                    required
                    // placeholder={t("users.email")}
                    onChange={changeHandler}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 my-2">
                  <label htmlFor="phone">{t("users.phone")}</label>
                  <input
                    type="tel"
                    name="phone"
                    value={state?.phone}
                    // placeholder={t("users.phone")}
                    onChange={(e) => changeHandler(e)}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3 my-2">
                  <label htmlFor="city">{t("users.city")}</label>
                  <input
                    type="text"
                    name="city"
                    value={state?.city}
                    // placeholder={t("users.city")}
                    onChange={(e) => changeHandler(e)}
                    className="form-control"
                  />
                </div>

                <div className="col-md-3 my-2">
                  <label htmlFor="fax">{t("global.postCode")}</label>
                  <input
                    type="text"
                    name="postCode"
                    value={state?.postCode ?? ""}
                    // placeholder={t("users.postCode")}
                    onChange={(e) => changeHandler(e)}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3 my-2">
                  <label htmlFor="street">{t("global.street")}</label>
                  <input
                    type="text"
                    name="street"
                    value={state?.street ?? ""}
                    // placeholder={t("users.address")}
                    onChange={(e) => changeHandler(e)}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3 my-2">
                  <label htmlFor="fax">{t("global.fax")}</label>
                  <input
                    type="text"
                    name="fax"
                    value={state?.fax ?? ""}
                    // placeholder={t("users.fax")}
                    onChange={(e) => changeHandler(e)}
                    className="form-control"
                  />
                </div>
              </div>
              <Col md={6} className=" mt-2">
                <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
                >
                  {t("global.active")}
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input switch-custom"
                    type="checkbox"
                    name="isActive"
                    checked={state?.isActive}
                    onChange={switchHandler2}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </Col>

              <div className="mt-3">
                <label htmlFor="imageFile"> {t("download.image")}</label>
                <input
                  onChange={fileHandler}
                  className="form-control"
                  type="file"
                  accept="image/*"
                  id="imageFile"
                />
              </div>
              {state?.image && (
                <div className="col-md-6 py-3">
                  <img
                    className="rounded"
                    alt="200x200"
                    width={200}
                    src={PICTURE_URL + state?.image.path}
                    data-holder-rendered="true"
                  />
                </div>
              )}
              <div className="my-3">
                <ul
                  className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
                  role="tablist"
                >
                  {website?.map((site: string) => {
                    return (
                      <li
                        className="nav-item"
                        key={site}
                        onClick={() => changeActiveTab(site)}
                      >
                        <a
                          className={`nav-link ${
                            activeTab === site ? "active" : ""
                          }`}
                          data-bs-toggle="tab"
                          href={`#${site}`}
                          role="tab"
                          // aria-selected={website === currentWebsite}
                        >
                          {t(`enums.${site}`)}
                          {/* {site} */}
                        </a>
                      </li>
                    );
                  })}
                </ul>
                <div className="tab-content row mt-4">
                  {(websitModules as any)?.[activeTab]?.map(
                    (access: string) => {
                      return (
                        <Col
                          md={4}
                          className="mb-3"
                          id={`${activeTab}-${access}`}
                        >
                          <div className="form-check form-switch p-0">
                            <label
                              className="d-block text-start"
                              htmlFor="flexSwitchCheckDefault"
                            >
                              {t(`settings.${access}`)}
                            </label>
                            <div className="row">
                              <input
                                id={`${activeTab}-${access}`}
                                className="form-check-input switch-custom ms-4 mt-2"
                                type="checkbox"
                                name={access}
                                checked={
                                  id
                                    ? state?.administrationData?.[
                                        activeTab
                                      ]?.includes(access)
                                    : checkedValue?.includes(access)
                                }
                                onChange={switchHandler}
                                role="switch"
                              />
                            </div>
                          </div>
                        </Col>
                      );
                    }
                  )}
                </div>
              </div>
              <div className="col-12 my-2 d-flex justify-content-end text-align-center">
                <div>
                  <button
                    className="btn btn-info"
                    onClick={() => {
                      navigate("/admin");
                    }}
                  >
                    {t("global.cancel")}
                  </button>
                  <button
                    className="btn btn-primary ms-3"
                    onClick={(e) => submitHandler(e, +id!)}
                  >
                    {state?.id
                      ? `${t("global.update")}`
                      : `${t("global.create")}`}
                  </button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
        {id && (
          <div
            className="tab-pane fade"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <Card>
              <Card.Body>
                <Col md={4}>
                  <label htmlFor="password" className="required-field">
                    {t("global.password")}
                  </label>
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Col>
                <div className="col-12 my-2 d-flex justify-content-end text-align-center">
                  <div>
                    <button
                      className="btn btn-primary ms-3"
                      onClick={changePassword}
                    >
                      {`${t("global.save")}`}
                    </button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        )}
      </div>
    </>
  );
};
