import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ErrorToast } from "../../shared/toasters/toasters";
import { List } from "./components/List";
import { AdminEventSummaryService } from "./service";
import Select, { SingleValue } from "react-select";
import { AdminEventService } from "../event/service";

export const AdminEventSummary = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const [eventList, setEventList] = useState([]);
  const [list, setList] = useState(undefined);
  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const mappedData = eventList?.map((position: any) => ({
    label: position.title,
    value: position.id,
  }));
  const editHandler = (id: number) => {
    navigate(`/admin/event-summary/update/${id}`);
  };
  const handleSelectEvent = (
    selectedSingle: SingleValue<{ label: string; value: number }>
  ) => {
    if (selectedSingle?.value as number) {
      setPagination((prev: any) => ({
        ...prev,
        event: selectedSingle?.value,
        page: 1,
      }));
    } else {
      setPagination((prev: any) => ({
        ...prev,
        event: null,
        page: 1,
      }));
    }
  };
  useEffect(() => {
    AdminEventService.getAll({ perPage: 0, page: 1 })
      .then((response) => {
        const { data } = response;
        setEventList(data?.items);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    AdminEventSummaryService.getAll(pagination)
      .then((response) => {
        const { data } = response;
        setList(data?.items);
        setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-body d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-3">
              <div className="search-box">
                <input
                  type="text"
                  className="form-control"
                  id="searchMemberList"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPagination((prev) => ({
                      ...prev,
                      term: e.target.value,
                      page: 1,
                    }))
                  }
                  placeholder={t("global.search")}
                />
                <i className="ri-search-line search-icon"></i>
              </div>
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    minWidth: "190px",
                  }),
                }}
                className="react-select-field"
                isClearable
                onChange={(e) => {
                  handleSelectEvent(
                    e as SingleValue<{ label: string; value: number }>
                  );
                }}
                options={mappedData}
                isSearchable={false}
                placeholder={t("global.choose_event")}
                required
              />
            </div>
            <button
              className="btn btn-primary"
              onClick={() => navigate("/admin/event-summary/create")}
            >
              {t("global.add")}
            </button>
          </div>
        </div>
      </div>

      <div className="col-12">
        <List
          page={page}
          handlePages={handlePages}
          totalPages={totalPages}
          list={list!}
          setTriggerUpdate={setTriggerUpdate}
          editHandler={editHandler}
        />
      </div>
    </>
  );
};
