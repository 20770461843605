import { useEffect, useState } from "react";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { useTranslation } from "react-i18next";
import { AdminTeamService } from "../service";
import { useNavigate } from "react-router-dom";
import { AdminMagazineCategoryService } from "../../magazineCategory/service";
import { CreateForm } from "./CreateForm";
import { Form } from "react-bootstrap";

export const CreateTeam = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [categories, setCategories] = useState();
  const [state, setState] = useState<any>();
  const [image, setImage] = useState<File>();
  const [document, setDocument] = useState<File>();

  const submitHandler = () => {
    const formData = new FormData();
    formData.append("body", JSON.stringify(state));
    image && formData.append("image", image);
    AdminTeamService.create(formData)
      .then((response) => {
        navigate("/admin/team");
        SuccessToast(t(`team.successfully_created`));
      })
      .catch((error) => ErrorToast(error));
  };

  useEffect(() => {
    AdminMagazineCategoryService.getAll({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setCategories(data?.items);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler();
        }}
      >
        <CreateForm
          image={image}
          document={document}
          setImage={setImage}
          setDocument={setDocument}
          categories={categories}
          state={state}
          setState={setState}
        />
      </Form>
    </div>
  );
};
