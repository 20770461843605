import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <section className="auth-page-wrapper py-5 position-relative d-flex align-items-center justify-content-center min-vh-100 bg-light">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="row g-0">
                  <div className="col-lg-5">
                    <div className="card auth-card bg-primary h-100 border-0 shadow-none p-sm-3 overflow-hidden mb-0">
                      <div className="card-body p-4 d-flex justify-content-between flex-column">
                        <div className="auth-image mb-3">
                          <img
                            src="assets/images/logo-light-full.png"
                            alt=""
                            height="26"
                          />
                          <img
                            src="assets/images/effect-pattern/auth-effect-2.png"
                            alt=""
                            className="auth-effect-2"
                          />
                          <img
                            src="assets/images/effect-pattern/auth-effect.png"
                            alt=""
                            className="auth-effect"
                          />
                          <img
                            src="assets/images/effect-pattern/auth-effect.png"
                            alt=""
                            className="auth-effect-3"
                          />
                        </div>

                        <div>
                          <h3 className="text-white">
                            Start your journey with us.
                          </h3>
                          <p className="text-white-75 fs-15">
                            It brings together your tasks, projects, timelines,
                            files and more
                          </p>
                        </div>
                        <div className="text-center text-white-75">
                          <p className="mb-0">
                            &copy;
                            <script>
                              document.write(new Date().getFullYear())
                            </script>{" "}
                            Hybrix. Crafted with{" "}
                            <i className="mdi mdi-heart text-danger"></i> by
                            Themesbrand
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-7">
                    <div className="card mb-0 border-0 shadow-none">
                      <div className="card-body px-0 p-sm-5 m-lg-4">
                        <div className="error-img text-center px-5">
                          <img
                            src="assets/images/error400.png"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="mt-4 text-center pt-3">
                          <div className="position-relative">
                            <h4 className="fs-18 error-subtitle text-uppercase mb-0">
                              Opps, page not found
                            </h4>
                            <p className="fs-15 text-muted mt-3">
                              It will be as simple as Occidental in fact, it
                              will Occidental to an English person
                            </p>
                            <div className="mt-4">
                              <Link to={"/app"}>
                                {" "}
                                <i className="mdi mdi-home me-1"></i>Back to
                                home
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageNotFound;
