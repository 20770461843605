import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { CreateForm } from "./CreateForm";
import { AdminBrandService } from "../../brand/service";
import { AdminModelCategoryService } from "../../modelCategory/service";
import {AdminModelService, ModelGalleryService} from "../service";

export const UpdateModel = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [brands, setBrands] = useState([]);
  const [modelCats, setModelCats] = useState([]);
  const { t } = useTranslation();
  const [state, setState] = useState<any>({
    title: "",
    description: "",
    specification: "",
    text: "",
    brand: 0,
    brandCategory: 0,
    isActive: true,
  });
  const [image, setImage] = useState<File>();
const [photoAlbums, setPhotoAlbums] = useState<File[]>([]);
  const submitHandler = () => {
    if (state?.id) {
      const albumData = new FormData();
      const formData = new FormData();
      formData.append(
        "body",
        JSON.stringify({ ...state, isActive: state?.active ?? false })
      );
      image && formData.append("image", image);
        if (photoAlbums) {
            for (let image of photoAlbums) {
                albumData.append("image[]", image as File);
            }
            albumData.append('body', JSON.stringify({model: state?.id}))
            ModelGalleryService.create(albumData)
                .then()
                .catch((error) => ErrorToast(error));
        }
      AdminModelService.update(formData, Number(id))
        .then((response) => {
          SuccessToast(t(`model.successfully_updated`));
          navigate("/admin/model");
          setState({});
        })
        .catch((error) => ErrorToast(error));
    }
  };
  useEffect(() => {
    AdminBrandService.getAll({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setBrands(data?.items ?? []);
      })
      .catch((error) => ErrorToast(error));
    AdminModelCategoryService.getAll({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setModelCats(data?.items ?? []);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler();
        }}
      >
        <CreateForm
          brands={brands}
          modelCats={modelCats}
          state={state}
          setState={setState}
          image={image}
          setPhotoAlbums={setPhotoAlbums}
          setImage={setImage}
        />
      </Form>
    </div>
  );
};
