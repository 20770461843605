import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import warningIcon from "../../../../shared/icons/warningIcon.json";
import { AdminNewsLetterService } from "../../service";
import { ErrorToast } from "../../../../shared/toasters/toasters";
import { useNavigate } from "react-router-dom";
import InnerHTML from "dangerously-set-html-content";
const AdminNewsletterStep3 = ({ state, step, changeStep, handleShow }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [script, setScript] = useState(
    '<script>alert("Injected script executed!");</script>'
  );

  const [preview, setPreview] = useState<{ html: string }>({ html: "" });
  const data = `<img src="" onerror=" onError={handleImageError}" />`;
  const html = `
  <div>This wil be rendered</div>
  <script>
    alert('testing')
  </script>

`;
  useEffect(() => {
    if (step !== 3) return;
    AdminNewsLetterService.preview({
      ...state,
      misterText: state?.textMan,
      womanText: state?.textWoman,
      othersText: state?.textOther,
    })
      .then((response) => {
        setPreview(response.data);
      })
      .catch((error) => ErrorToast(error));
  }, [step]);

  return (
    <>
      {/* <img src={"NESTO"} onError={handleImageError} /> */}
      {preview.html && (
        <div className="p-3">
          <InnerHTML html={preview.html} />
        </div>
      )}
    </>
  );
};

export default AdminNewsletterStep3;
