import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {CreateForm} from "./CreateForm";
import {Form} from "react-bootstrap";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {MAGAZINE_URL} from "../../../helpers/api.routes";
import {DocImgFormData} from "../../../helpers/api.services";
import {useTranslation} from "react-i18next";
import {AdminMagazineCategoryService} from "../../magazineCategory/service";
import {AdminMagazineIssueService} from "../../magazineIssue/service";

export const UpdateMagazine = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState();
  const { t } = useTranslation();
  const [state, setState] = useState<any>();
  const [image, setImage] = useState<File>();
  const [issues, setIssues] = useState();
  const [file, setFile] = useState<File>();
    const submitHandler = (event: React.FormEvent, id?: number) => {
        if (state?.id) {
            DocImgFormData(
                file,
                image,
                `${MAGAZINE_URL}/${state?.id}`,
                JSON.stringify({
                    ...state,
                    issue: state?.issue?.id ?? state?.issue,
                }),
                "post",
                "file"
            )
                .then((response) => {
                    SuccessToast(t(`magazine.successfully_updated`));
                    // setIsMenuOpened(false);
                    setState({});
                    navigate("/admin/magazine");
                })
                .catch((error) => ErrorToast(error));
        }
    };
  useEffect(() => {
    AdminMagazineCategoryService.getAll({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setCategories(data);
      })
      .catch((error) => ErrorToast(error));
    AdminMagazineIssueService.getAll({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setIssues(data?.items);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler(e, state?.id);
        }}
      >
        <CreateForm
          issues={issues}
          image={image}
          file={file}
          setImage={setImage}
          setFile={setFile}
          categories={categories}
          state={state}
          setState={setState}
        />
      </Form>
    </div>
  );
};
