import React, {useEffect, useState} from "react";
import {ErrorToast, SuccessToast} from "../../shared/toasters/toasters";
import {useTranslation} from "react-i18next";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import {useNavigate} from "react-router-dom";
import Select from "react-select";
import {AdminTeamService} from "./service";
import {List} from "./components/List";
import {Pagination} from "../../shared/paginations/Paginations";
import {AdminTeamCategoryService} from "../teamCategory/service";

export const Team = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedPrompt, setSelectedPrompt] = useState<any>();
  const [show, setShow] = useState(false);
  const [teamCategories, setTeamCategories] = useState<any>([{}]);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    term: "",
  });

  const [list, setList] = useState<any>();
  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const handleShow = () => setShow(true);

  const deleteHandler = (selectedPrompt: any) => {
    selectedPrompt?.id &&
      AdminTeamService.delete(selectedPrompt?.id)
        .then((response) => {
          if (response?.status === "success") {
            setList((prev: any) =>
              prev.filter((item: any) => item.id !== selectedPrompt.id)
            );
            // setTriggerUpdate(!triggerUpdate);
            SuccessToast(t(`team.successfully_deleted`));
            setTriggerUpdate((prev: boolean) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  const editHandler = (id: number): void => {
    navigate(`/admin/team/update/${id}`);
  };
  const updatePosition = (newList: any) => {
    const newListUpdated = newList?.map((item: any) => item.id);
    AdminTeamService.updatePosition({ ids: newListUpdated });
  };

  const createNew = (): void => {
    navigate("/admin/team/create");
  };

  useEffect(() => {
    AdminTeamService.getAll(pagination)
      .then((response) => {
        const { data } = response;
        setList(
            data?.items
        );
        setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);

  useEffect(() => {
    AdminTeamCategoryService.getAll({ perPage: 0, page: 1 })
        .then((response) => {
          const { data } = response;
            setTeamCategories(data?.items?.map((category: {name: string, id: number}) => ({
                label: category.name,
                value: category.id,
            })));
        })
        .catch((error) => ErrorToast(error));
  }, []);
  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-body d-flex align-items-center ">
            <div className="d-flex align-items-center gap-3">
              <div className="search-box">
                <input
                  type="text"
                  className="form-control"
                  id="searchMemberList"
                  onChange={(e: any) =>
                    setPagination((prev: any) => ({
                      ...prev,
                      page: 1,
                      term: e.target.value,
                    }))
                  }
                  placeholder={t("global.search")}
                />
                <i className="ri-search-line search-icon"></i>
              </div>
              <div>
                <Select
                    className="react-select-field"
                    onChange={(e: any) => {
                      setPagination((prev: any) => ({
                        ...prev,
                        page: 1,
                        category: e?.value,
                      }));
                    }}
                    options={teamCategories}
                    isClearable
                    isSearchable={false}
                    placeholder={t("team.team_category")}
                    required
                />
              </div>
            </div>
            <button className="btn btn-primary ms-auto" onClick={createNew}>
              {t("global.add")}
            </button>
          </div>
        </div>
      </div>
      {page > 1 || pagination?.term !== "" ? (
        <>
          <div className="col-12">
            <List
              // page={page}
              // handlePages={handlePages}
              // totalPages={totalPages}
              list={list!}
              setTriggerUpdate={setTriggerUpdate}
              editHandler={editHandler}
              // domain={domain}
            />
          </div>{" "}
        </>
      ) : (
        <>
          <div className="col-12">
            <DraggableBootstrapTable
              isDraggable={!!(pagination as any)?.category}
              preview={true}
              list={list!}
              setList={setList}
              headers={["id", "firstName", "lastName", "email", "active", "category"]}
              title="team"
              onEdit={editHandler}
              selectedPrompt={selectedPrompt}
              setSelectedPrompt={setSelectedPrompt}
              handleShow={handleShow}
              show={show}
              setShow={setShow}
              deleteHandler={deleteHandler}
              updatePosition={updatePosition}
            />
          </div>
        </>
      )}
      {page && (
        <div className="d-flex justify-content-end ">
          {totalPages <= 1 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
      )}
    </>
  );
};
