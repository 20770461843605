import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { AdminBrandService } from "./service";
import { useTranslation } from "react-i18next";
import { CreateForm } from "./components/CreateForm";
import { List } from "./components/List";
import { useNavigate } from "react-router-dom";

export const AdminBrand = () => {
  const { t } = useTranslation();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const navigate = useNavigate();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [image, setImage] = useState<File>();
  const [currentBrand, setCurrentBrand] = useState<any>();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const [brandsList, setBrandsList] = useState(undefined);
  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const editHandler = (brandId: number) => {
    navigate(`/admin/brand/update/${brandId}`);
    // setIsMenuOpened(true);
    // AdminBrandService.getSingle(brandId)
    //   .then((response) => {
    //     const { data } = response;
    //     setCurrentBrand(data);
    //     window.scrollTo(0, 0);
    //   })
    //   .catch((error) => ErrorToast(error));
  };
  const submitHandler = () => {
    const formData = new FormData();
    formData.append("body", JSON.stringify(currentBrand));
    image && formData.append("image", image);
    if (currentBrand.id) {
      AdminBrandService.update(formData, currentBrand.id)
        .then((response) => {
          SuccessToast(t(`brand.successfully_updated`));
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
          setCurrentBrand({});
        })
        .catch((error) => ErrorToast(error));
    } else {
      AdminBrandService.create(formData)
        .then((response) => {
          SuccessToast(t(`brand.successfully_created`));
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
          setCurrentBrand({});
        })
        .catch((error) => ErrorToast(error));
    }
  };
  useEffect(() => {
    AdminBrandService.getAll(pagination)
      .then((response) => {
        const { data } = response;
        setBrandsList(data?.items);
        setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);
  return (
    <>
      {!isMenuOpened && (
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row g-2">
                <div className="col-sm-4">
                  <div className="search-box">
                    <input
                      type="text"
                      className="form-control"
                      id="searchMemberList"
                      placeholder="Search for name or designation..."
                    />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </div>
                <div className="col-sm-auto ms-auto">
                  <div className="list-grid-nav hstack gap-1">
                    <button
                      className="btn btn-info"
                      onClick={() => navigate("/admin/brand/create")}
                    >
                      {t("global.add")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isMenuOpened && (
        <div className="col-12">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              submitHandler();
            }}
          >
            <CreateForm
              setIsMenuOpened={setIsMenuOpened}
              state={currentBrand}
              setState={setCurrentBrand}
              image={image}
              setImage={setImage}
            />
          </Form>
        </div>
      )}
      <div className="col-12">
        <List
          page={page}
          handlePages={handlePages}
          totalPages={totalPages}
          brandsList={brandsList}
          setTriggerUpdate={setTriggerUpdate}
          editHandler={editHandler}
        />
      </div>
    </>
  );
};
