import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { AdminNewsLetterService } from "./service";
import { List } from "./components/List";
import { CreateForm } from "./components/CreateForm";
import { useTranslation } from "react-i18next";
import { AdminNewsService } from "../../../news/service";
import DraggableBootstrapTable from "../../../../shared/components/draggableList/Draggable";
import { NewsletterSettingsService } from "../../adminNewsletterSettings/service";
// import { N1ED } from "@edsdk/n1ed-react";
// import { Editor } from "tinymce";
import "../../../../assets/scss/EditorStyle.css";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import { editorConfigurationCustom } from "../../../../shared/functions/editorConfigurationCustom";
import { InitEditorMoreFields } from "../../../model/components/CreateForm";
// @ts-ignore
// import Editor from "ckeditor5-custom-build/build/ckeditor";
export const AdminNewsletterStep2 = ({
  selectedNews,
  setSelectedNews,
  state,
  setState,
  step,
}: any) => {
  const { t } = useTranslation();
  const [count, setCount] = useState({});
  const [selectEditor, setSelectEditor] = useState<boolean>(false);
  const [editorData, setEditorData] = useState<any>(false);
  const firstLoad = useRef(true);
  const [editorLoading, setEditorLoading] = useState<boolean>(false);
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  // const changeEditorHandler = (content: string, editor: Editor) => {
  //   setState((prev: any) => ({ ...prev, [editor?.id]: content }));
  //   if (!firstLoad.current) {
  //     setEditorData(editor);
  //   }
  //   firstLoad.current = false;
  // };
  const handleTextMr = (textMan: string) => {
    setState((prev: any) => ({ ...prev, textMan }));
  };
  const handleTextWoman = (textWoman: string) => {
    setState((prev: any) => ({ ...prev, textWoman }));
  };
  const handleTextOther = (textOther: string) => {
    setState((prev: any) => ({ ...prev, textOther }));
  };
  const switchHandler = (e: any) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  const changeHandlerEditor = (value: string, eventName: string) => {
    setState((prev: any) => ({ ...prev, [eventName]: value }));
  };
  useEffect(() => {
    AdminNewsLetterService.getCount()
      .then((response) => {
        const { data } = response;
        setCount(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    if (step === 2 && !state?.subject) {
      console.log(" NewsletterSettingsService.getAll");
      firstLoad.current = true;
      NewsletterSettingsService.getAll()

        .then((response) => {
          setEditorData(false);
          const { data } = response;
          console.log(data);
          InitEditorMoreFields(
            data?.textMan ?? undefined,
            handleTextMr,
            ".textMan"
          );
          InitEditorMoreFields(
            data?.textWoman ?? undefined,
            handleTextWoman,
            ".textWoman"
          );
          InitEditorMoreFields(
            data?.textOther ?? undefined,
            handleTextOther,
            ".textOther"
          );
          setState({
            news: state?.news,
            breakingNews: state?.breakingNews ?? false,
            test: state?.test ?? false,
            subject: state?.subject ?? "",
            ...data,
          });
          setEditorLoading((prev: boolean) => !prev);
        })
        .catch((error) => ErrorToast(error));
    } else if (state?.subject) {
      InitEditorMoreFields(
        state?.textMan ?? undefined,
        handleTextMr,
        ".textMan"
      );
      InitEditorMoreFields(
        state?.textWoman ?? undefined,
        handleTextWoman,
        ".textWoman"
      );
      InitEditorMoreFields(
        state?.textOther ?? undefined,
        handleTextOther,
        ".textOther"
      );
    } else {
      return;
    }
  }, [step]);
  return (
    <>
      {step === 2 && state && (
        <>
          <div className="card">
            <div className="card-body">
              <Row>
                <Col md={12} className="mb-3">
                  <label htmlFor="title" className="required-field">
                    {t("newsletter.subject")}
                  </label>
                  <input
                    type="text"
                    name="subject"
                    className="form-control"
                    required
                    onChange={changeHandler}
                    value={state?.id && state?.subject}
                  />
                </Col>
                <Col md={4} className="">
                  <div className="form-check form-switch p-0">
                    <label
                      className="d-block text-start"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      {t("global.test")} ({t("global.total")}:{" "}
                      {(count as any)?.subscribersTest})
                    </label>
                    <div className="row">
                      <input
                        className="form-check-input switch-custom ms-4 mt-2"
                        type="checkbox"
                        name="test"
                        checked={state?.test}
                        onChange={switchHandler}
                        role="switch"
                        id="flexSwitchCheckDefault"
                      />
                    </div>
                  </div>
                </Col>
                <Col md={4} className="">
                  <div className="form-check form-switch p-0">
                    <label
                      className="d-block text-start"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      {t("global.breakingNews")} ({t("global.total")}:{" "}
                      {(count as any)?.subscribers})
                    </label>
                    <div className="row">
                      <input
                        className="form-check-input switch-custom ms-4 mt-2"
                        type="checkbox"
                        name="breakingNews"
                        checked={state?.breakingNews}
                        onChange={switchHandler}
                        role="switch"
                        id="flexSwitchCheckDefault"
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12} className="my-3" id="custom-editor">
                  <label htmlFor="title" className="required-field">
                    {t("newsletter.mr")}
                  </label>
                  <textarea
                    className="editor textMan"
                    onChange={console.log}
                  ></textarea>
                  {/* <N1ED
                    id={`textMan`}
                    onFocus={(e) => setSelectEditor(true)}
                    apiKey={process.env.REACT_APP_EDITOR_KEY}
                    initialValue={state?.textMan ?? ""}
                    // initialValue={
                    //   editorData ? editorData : state?.textMan ?? ""
                    // }
                    onEditorChange={changeEditorHandler}
                  /> */}
                  {/* <CKEditor
                    config={editorConfigurationCustom}
                    editor={Editor}
                    data={state?.textMan ?? ""}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      changeHandlerEditor(data, "textMan");
                    }}
                  /> */}
                </Col>
                <Col md={12} className="mb-3" id="custom-editor">
                  <label htmlFor="title" className="required-field">
                    {t("newsletter.woman")}
                  </label>
                  <textarea
                    className="editor textWoman"
                    onChange={console.log}
                  ></textarea>
                  {/* <N1ED
                    id={`textWoman`}
                    onFocus={(e) => setSelectEditor(true)}
                    apiKey={process.env.REACT_APP_EDITOR_KEY}
                    initialValue={state?.textWoman ?? ""}
                    // initialValue={
                    //   editorData ? editorData : state ? state?.textWoman : ""
                    // }
                    onEditorChange={changeEditorHandler}
                  /> */}
                  {/* <CKEditor
                    config={editorConfigurationCustom}
                    editor={Editor}
                    data={state?.textWoman ?? ""}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      changeHandlerEditor(data, "textWoman");
                    }}
                  /> */}
                </Col>
                <Col md={12} className="mb-3" id="custom-editor">
                  <label htmlFor="title" className="required-field">
                    {t("newsletter.other")}
                  </label>
                  <textarea
                    className="editor textOther"
                    onChange={console.log}
                  ></textarea>
                  {/* <N1ED
                    id={`textOther`}
                    onFocus={(e) => setSelectEditor(true)}
                    apiKey={process.env.REACT_APP_EDITOR_KEY}
                    initialValue={state?.textOther ?? ""}
                    // initialValue={
                    //   editorData ? editorData : state ? state?.textOther : ""
                    // }
                    onEditorChange={changeEditorHandler}
                  /> */}
                  {/* <CKEditor
                    config={editorConfigurationCustom}
                    editor={Editor}
                    data={state?.textOther ?? ""}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      changeHandlerEditor(data, "textOther");
                    }}
                  /> */}
                </Col>
              </Row>
            </div>
          </div>
          <div className="col-12">
            <DraggableBootstrapTable
              list={selectedNews}
              setList={setSelectedNews}
              headers={["id", "title", "series"]}
              title="news"
              haveActions={false}
            />
          </div>
        </>
      )}
    </>
  );
};
