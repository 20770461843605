import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { AdminNewsletterEmailService } from "./service";
import { List } from "./components/List";
import {Pagination} from "../../../shared/paginations/Paginations";

interface AdminBannerProps {}

interface BannerItem {
  // Define the properties of a newsletter_email item
  // Adjust these based on your actual data structure
  id: number;
  // ... other properties
}

interface BannerListResponse {
  items: BannerItem[];
  totalItems: number;
  perPage: number;
}

const AdminNewsletterEmailCreate: React.FC<AdminBannerProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState(
    location.pathname.split("/")[location.pathname.split("/").length - 1] ===
      "registered"
      ? 1
      : 0
  );
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    active: active,
    mail: "",
  });
  const [triggerUpdate, setTriggerUpdate] = useState<boolean>(false);
  const [list, setList] = useState<BannerItem[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const perPage: number = 10;

  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };

  const editHandler = (id: number): void => {
    navigate(`/admin/newsletter-email/update/${id}`);
  };

  const fetchBannerList = async (): Promise<void> => {
    try {
      const response = await AdminNewsletterEmailService.getAll({
        page: pagination?.page,
        perPage: pagination.perPage,
        active: active,
        mail: pagination?.mail,
      });
      const { data } = response as { data: BannerListResponse };
      setList(data?.items);
      setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
      setTotalItems(data?.totalItems);
    } catch (error) {
      ErrorToast(error);
    }
  };

  useEffect(() => {
    fetchBannerList();
  }, [triggerUpdate, page, active]);

  useEffect(() => {
    const checkActive =
      location.pathname.split("/")[location.pathname.split("/").length - 1] ===
      "registered"
        ? 1
        : 0;
    setActive(checkActive);
  });

  const createNewBanner = (): void => {
    navigate("/admin/newsletter-email/create");
  };

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between gap-3">
              <div className="search-box">
                <input
                  type="text"
                  className="form-control"
                  id="searchMemberList"
                  onChange={(e: any) => {
                    setPagination((prev: any) => ({
                      ...prev,
                      mail: e.target.value,
                    }));
                    setTriggerUpdate((prev: boolean) => !prev);
                  }}
                  placeholder={t("global.search_per_email")}
                />
                <i className="ri-search-line search-icon"></i>
              </div>
              <button className="btn btn-primary" onClick={createNewBanner}>
                {t("global.add")}
              </button>
            </div>
          </div>
          {/* <div className="card-body d-flex align-items-center justify-content-between">
            <h4 className="card-title mg-b-0">
              {t("newsletter_email.create_new_newsletter_email")}
            </h4>
            <button className="btn btn-primary" onClick={createNewBanner}>
              {t("global.add")}
            </button>
          </div> */}
        </div>
      </div>

      <div className="col-12">
        <List
          page={page}
          handlePages={handlePages}
          totalPages={totalPages}
          list={list}
          setTriggerUpdate={setTriggerUpdate}
          editHandler={editHandler}
          active={Boolean(+active)}
          totalItems={totalItems}
        />
      </div>
    </>
  );
};

export default AdminNewsletterEmailCreate;
