import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CreateForm } from "./CreateForm";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { useTranslation } from "react-i18next";
import { IModel } from "../interface";
import { AdminMarketService } from "../service";

export const UpdateMarket = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const { t } = useTranslation();
  const [state, setState] = useState<IModel>({
    title: "",
    description: "",
    isActive: true,
  });

  const submitHandler = () => {
    AdminMarketService.update(state, +id!)
      .then((response) => {
        SuccessToast(t(`market.successfully_updated`));
        navigate("/admin/market");
      })
      .catch((error) => ErrorToast(error));
  };

  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler();
        }}
      >
        <CreateForm categories={categories} state={state} setState={setState} />
      </Form>
    </div>
  );
};
