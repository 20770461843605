import {useEffect} from "react";
import {Card, Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {AdminUserService} from "../service";
import {useNavigate, useParams} from "react-router-dom";
import {ErrorToast} from "../../../shared/toasters/toasters";
import {themeSelect} from "../../../shared/components/Select";
import Select, {SingleValue} from "react-select";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";

export const CreateForm = ({
  state,
  setState,
  setPassword,
  changePassword,
  submitHandler,
}: any) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userStatus } = useSelector((state: RootState) => state.enum);
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  const switchHandler = (e: any) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  const handleSelectStatus = (
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) => {
    if (selectedSingle?.value as string) {
      setState({
        ...state,
        status: selectedSingle?.value as string,
      } as any);
    } else {
      setState({
        ...state,
        status: null,
      } as any);
    }
  };
  useEffect(() => {
    if (!!Number(id)) {
      AdminUserService.getSingle(+id!)
        .then((response) => {
          const { data } = response;
          setState(data);
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error));
    }
  }, [id]);
  return (
    <>
      <ul
        className="nav nav-tabs nav-tabs-custom rounded card-header-tabs"
        id="myTab"
        role="tablist"
      >
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active padding-active"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            {t("global.subscriber")}
          </button>
        </li>
        {id && (
          <li className="nav-item" role="presentation">
            <button
              className="nav-link padding-active"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
            >
              {t("global.password")}
            </button>
          </li>
        )}
      </ul>
      <div className="tab-content mt-1" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          {" "}
          <Card>
            <Card.Body>
              <Row>
                <Col md={6}>
                  <label htmlFor="firstName" className="required-field">
                    {t("global.firstName")}
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    className="form-control"
                    required
                    onChange={(e) => changeHandler(e as any)}
                    value={state?.id && state?.firstName}
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <label htmlFor="lastName" className="required-field">
                    {t("global.lastName")}
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    className="form-control"
                    required
                    onChange={(e) => changeHandler(e as any)}
                    value={state?.id && state?.lastName}
                  />
                </Col>
                <Col md={6}>
                  <label htmlFor="username" className="required-field">
                    {t("global.username")}
                  </label>
                  <input
                    type="text"
                    name="username"
                    className="form-control"
                    required
                    onChange={(e) => changeHandler(e as any)}
                    value={state?.id && state?.username}
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <label htmlFor="email" className="required-field">
                    {t("global.email")}
                  </label>
                  <input
                    type="text"
                    name="email"
                    className="form-control"
                    required
                    onChange={(e) => changeHandler(e as any)}
                    value={state?.id && state?.email}
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <label htmlFor="lastName" className="required-field">
                    {t("global.phone")}
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    className="form-control"
                    required
                    onChange={(e) => changeHandler(e as any)}
                    value={state?.id && state?.phone}
                  />
                </Col>
                {!id && (
                  <Col md={6}>
                    <label htmlFor="password" className="required-field">
                      {t("global.password")}
                    </label>
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      required
                      onChange={(e) => changeHandler(e as any)}
                      value={state?.id && state?.password}
                    />
                  </Col>
                )}

                <Col md={3} className="mb-3">
                  <label htmlFor="company" className="required-field">
                    {t("form.company")}
                  </label>
                  <input
                    type="text"
                    name="company"
                    className="form-control"
                    required
                    onChange={(e) => changeHandler(e as any)}
                    value={state?.id && state?.company}
                  />
                </Col>
                <Col md={3}>
                  <label htmlFor="street" className="required-field">
                    {t("global.street")}
                  </label>
                  <input
                    type="text"
                    name="street"
                    className="form-control"
                    required
                    onChange={(e) => changeHandler(e as any)}
                    value={state?.id && state?.street}
                  />
                </Col>
                <Col md={3}>
                  <label htmlFor="street" className="required-field">
                    {t("global.city")}
                  </label>
                  <input
                    type="text"
                    name="city"
                    className="form-control"
                    required
                    onChange={(e) => changeHandler(e as any)}
                    value={state?.id && state?.city}
                  />
                </Col>
                <Col md={3}>
                  <label htmlFor="street" className="required-field">
                    {t("global.postCode")}
                  </label>
                  <input
                    type="text"
                    name="postCode"
                    className="form-control"
                    required
                    onChange={(e) => changeHandler(e as any)}
                    value={state?.id && state?.postCode}
                  />
                </Col>
                <Col md={4} className="mt-auto">
                  <Select
                    isClearable={true}
                    theme={themeSelect}
                    placeholder={t("global.status")}
                    defaultValue={
                      state?.status && {
                        label: state?.status as string,
                        value: state?.status as string,
                      }
                    }
                    options={userStatus?.map((item: string) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(e) => {
                      handleSelectStatus(
                        e as SingleValue<{ label: string; value: string }>
                      );
                    }}
                  />
                </Col>
                <Col md={3} className=" mt-2">
                  <label
                    className="d-block text-start"
                    htmlFor="flexSwitchCheckDefault"
                  >
                    {t("global.active")}
                  </label>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input switch-custom"
                      type="checkbox"
                      name="isActive"
                      checked={state?.isActive}
                      onChange={switchHandler}
                      role="switch"
                      id="flexSwitchCheckDefault"
                    />
                  </div>
                </Col>
              </Row>
              <div className="col-12 my-2 d-flex justify-content-end text-align-center">
                <div>
                  <button
                    className="btn btn-info"
                    onClick={() => navigate("/admin/subscriber")}
                  >
                    {t("global.cancel")}
                  </button>
                  <button
                    className="btn btn-primary ms-3"
                    onClick={(e: any) => submitHandler(e, state.id)}
                  >
                    {state?.id
                      ? `  ${t("global.update")}`
                      : `${t("global.create")}`}
                  </button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
        {id && (
          <div
            className="tab-pane fade"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <Card>
              <Card.Body>
                <Col md={4}>
                  <label htmlFor="password" className="required-field">
                    {t("global.password")}
                  </label>
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    value={state?.id && state?.password}
                  />
                </Col>
                <div className="col-12 my-2 d-flex justify-content-end text-align-center">
                  <div>
                    <button
                      className="btn btn-primary ms-3"
                      onClick={changePassword}
                    >
                      {`${t("global.save")}`}
                    </button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        )}{" "}
      </div>
    </>
  );
};
