import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {ErrorToast} from "../../../../shared/toasters/toasters";
import {AdminNewsLetterSentService} from "../sevice";
import {useTranslation} from "react-i18next";
import InnerHTML from "dangerously-set-html-content";

interface NewsletterPreview {
    content: string;
}

export const Preview = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const {t} = useTranslation();
    const [newsletterPreview, setNewsletterPreview] = useState({} as NewsletterPreview);

    useEffect(() => {
        if (!!Number(id)) {
            AdminNewsLetterSentService.getPreview(Number(id))
                .then((response) => {
                    const {data} = response;
                    setNewsletterPreview(data);
                })
                .catch((error) => ErrorToast(error));
        }
    }, [id]);

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header px-3 pb-0">
                            <button
                                className="btn btn-sm btn-primary d-flex align-items-center mb-1"
                                onClick={() => navigate("/admin/newsletters/list")}
                            >
                                <i className="ri-arrow-left-line me-1 "/>
                                {t("global.back")}
                            </button>
                        </div>
                        <div className="card-body">
                            {newsletterPreview.content &&
                                <InnerHTML html={newsletterPreview?.content}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
