import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import ImageViewer from "react-simple-image-viewer";
import {ConfirmDelete} from "../../../shared/components/ConfirmDelete";
import {ModelGalleryService} from "../service";


export const ModelGalleryUpdate = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [show, setShow] = useState(false);
  const [state, setState] = useState<any[]>();
  const [currentImage, setCurrentImage] = useState(0);
  const [selectedImage, setSelectedImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState<any>();
  const openImageViewer = useCallback((index: any) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

    const handleShow = () => setShow(true);

  const deleteHandler = () => {
    selectedPrompt &&
    ModelGalleryService.delete(selectedPrompt?.id)
        .then((response) => {
          if (response?.status === "success") {
            SuccessToast(t(`news.successfully_deleted`));
            setTriggerUpdate((prev: boolean) => !prev);
            setSelectedImage(0);
          }
        })
        .catch((error) => ErrorToast(error));
  };

  useEffect(() => {
    if (!!Number(id)) {
      ModelGalleryService.getSingle(id as unknown as number)
        .then((response) => {
          const { data } = response;
          const mapped = data?.data?.map((doc: any) => {
            return {
              id: doc.image?.id,
              path: doc.image.path,
                title: doc.image?.originalName,
            };
          });

          setState(mapped);
        })
        .catch((error) => ErrorToast(error));
    }
  }, [id, triggerUpdate]);
  return (
    <>
      <div>

        {state?.map((item, index) => (
          <div className={'position-relative d-inline-block'} key={item?.id}>
              <img
                  src={item?.path}
                  className="object-fit-cover"
                  onDoubleClick={() => {
                      openImageViewer(index);
                      setSelectedImage(0);
                  }}
                  key={item?.id}
                  width={250}
                  height={250}
                  style={{
                      margin: "2px",
                      border: `${selectedImage === item?.id ? "2px solid red" : "none"}`,
                  }}
                  alt=""
              />
              <i onClick={() => {
                  handleShow();
                  setSelectedPrompt(item);
              }}
                 className={'ri-delete-bin-fill bg-danger text-white delete-icon-position'}></i>
          </div>
        ))}
        {isViewerOpen && (
          <ImageViewer
            src={state!?.map((img: any) => img?.path as unknown as string)}
            backgroundStyle={{ zIndex: 10000 }}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
      </div>

      <ConfirmDelete
        show={show}
        setShow={setShow}
        itemName={selectedPrompt?.title}
        deleteHandler={deleteHandler}
        product={selectedPrompt}
        selectedProduct={selectedPrompt}
      />
    </>
  );
};
