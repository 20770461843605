import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { AdminSubscriptionService } from "./service";
import { CreateForm } from "./components/CreateForm";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export const AdminSubscription = () => {
  const { t } = useTranslation();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState<any>();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [state, setState] = useState<any>({ isActive: true, isPro: false });
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 25,
    term: "",
  });
  const [list, setList] = useState(undefined);

  const updatePosition = (newList: any) => {
    const newListUpdated = newList?.map((item: any) => item.id);
    AdminSubscriptionService.updatePosition({ ids: newListUpdated });
  };
  const { subscriberCategory } = useSelector((state: RootState) => state.enum);

  const deleteHandler = () => {
    selectedPrompt?.id &&
      AdminSubscriptionService.delete(selectedPrompt?.id)
        .then((response) => {
          if (response?.status === "success") {
            SuccessToast(t(`toast.successfully_deleted`));
            setTriggerUpdate((prev: boolean) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  const mappedData = subscriberCategory?.map((category: any) => ({
    label: t(`enums.${category}`),
    value: category,
  }));
  const editHandler = (brandId: number) => {
    setIsMenuOpened(true);
    AdminSubscriptionService.getSingle(brandId)
      .then((response) => {
        const { data } = response;
        setState(data);
        window.scrollTo(0, 0);
      })
      .catch((error) => ErrorToast(error));
  };
  const submitHandler = () => {
    if (state.id) {
      AdminSubscriptionService.update(state, state.id)
        .then((response) => {
          SuccessToast(t(`subscription.successfully_updated`));
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
          setState({});
        })
        .catch((error) => ErrorToast(error));
    } else {
      AdminSubscriptionService.create(state)
        .then((response) => {
          SuccessToast(t(`subscription.successfully_created`));
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
          setState({});
        })
        .catch((error) => ErrorToast(error));
    }
  };
  useEffect(() => {
    AdminSubscriptionService.getAll(pagination)
      .then((response) => {
        const { data } = response;
        setList(data?.items);
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);

  return (
    <>
      {!isMenuOpened && (
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="card-title mg-b-0">
                  {t("subscription.create_new_subscription")}
                </h4>
                <button
                  className="btn btn-primary"
                  onClick={() => setIsMenuOpened(true)}
                >
                  {t("global.add")}
                </button>
              </div>
              <div className="d-flex align-items-center gap-3 mt-3">
                <div className="search-box">
                  <input
                    type="text"
                    className="form-control"
                    id="searchMemberList"
                    onChange={(e: any) =>
                      setPagination((prev: any) => ({
                        ...prev,
                        page: 1,
                        term: e.target.value,
                      }))
                    }
                    placeholder={t("global.search")}
                  />
                  <i className="ri-search-line search-icon"></i>
                </div>

                <Select
                  className="react-select-field"
                  onChange={(e: any) => {
                    setPagination((prev: any) => ({
                      ...prev,
                      page: 1,
                      category: e?.value,
                    }));
                  }}
                  options={mappedData}
                  value={mappedData?.find(
                    (category: any) => category.value === state?.category
                  )}
                  isClearable
                  isSearchable={false}
                  placeholder={t("subscription.subscription_category")}
                  required
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {isMenuOpened && (
        <div className="col-12">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              submitHandler();
            }}
          >
            <CreateForm
              setIsMenuOpened={setIsMenuOpened}
              state={state}
              setState={setState}
            />
          </Form>
        </div>
      )}
      <div className="col-12">
        <DraggableBootstrapTable
          isDraggable={!!(pagination as any)?.category}
          preview={true}
          list={list!}
          selectedPrompt={selectedPrompt}
          setSelectedPrompt={setSelectedPrompt}
          setList={setList}
          headers={["id", "name"]}
          title="subscription"
          handleShow={handleShow}
          show={show}
          setShow={setShow}
          onEdit={editHandler}
          deleteHandler={deleteHandler}
          updatePosition={updatePosition}
        />
      </div>
    </>
  );
};
