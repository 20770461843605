import React, {useEffect} from "react";
import {Card, Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {useNavigate, useParams} from "react-router-dom";
import {AdminMagazineService} from "../service";
import {ErrorToast} from "../../../shared/toasters/toasters";
import {PICTURE_URL} from "../../../helpers/api.routes";

export const CreateForm = ({
  setIsMenuOpened,
  state,
  setState,
  imageRef,
  categories,
  setImage,
  image,
  issues,
  file,
  setFile,
}: any) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  const fileHandler = (e: any) => {
    setImage(e.target.files[0]);
  };
  const selectHandler = (e: any) => {
    setState((prev: any) => ({ ...prev, category: e.value }));
  };
  const selectHandlerIssue = (e: any) => {
    setState((prev: any) => ({ ...prev, issue: e.value }));
  };
  const switchHandler = (e: any) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  const documentHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files![0]);
  };
  const mappedData = categories?.map((category: any) => ({
    label: category.name,
    value: category.id,
  }));
  const mappedDataIssues = issues?.map((issues: any) => ({
    label: issues.name,
    value: issues.id,
  }));
  useEffect(() => {
    if (!!Number(id)) {
      AdminMagazineService.getSingle(id as unknown as number)
        .then((response) => {
          const { data } = response;
          setState({ ...data, category: data?.category?.id });
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error));
    }
  }, [id]);
  return (
    <Card>
      <Card.Body>
        <Row>
          <div className="col-xl-12">
            <div className="alert alert-info" role="alert">
              <h6 className="d-inline me-2">Login:</h6>
              <a href="https://issuu.com/mylibrary">
                AUTO&Wirtschaft on ISSUU.COM{" "}
              </a>
              (Benutzername: <strong>awverlag</strong> / Passwort:
              <strong>awverlag123 )</strong>
            </div>
          </div>

          <Col md={6} className="mb-3">
            <label htmlFor="title" className="required-field">
              {t("global.title")}
            </label>
            <input
              type="text"
              name="title"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.title}
            />
          </Col>
          <Col md={6}>
            <label htmlFor="subtitle" className="required-field">
              {t("global.subtitle")}
            </label>
            <input
              type="text"
              name="subtitle"
              className="form-control"
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.subtitle}
            />
          </Col>
          <Col md={12} className="mb-3">
            <label htmlFor="embeddedCode" className="required-field">
              {t("table_headers.embedded_code")}
            </label>
            <textarea
              name="embeddedCode"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.embeddedCode}
              id="exampleFormControlTextarea5"
              rows={3}
            />
          </Col>
          <Col md={4}>
            <label htmlFor="category" className="required-field">
              {t("table_headers.category")}
            </label>
            <Select
              className="react-select-field"
              onChange={selectHandler}
              options={mappedData}
              value={mappedData?.find(
                (category: any) => category.value === state?.category
              )}
            />
          </Col>
          <Col md={4}>
            <label htmlFor="issue" className="required-field">
              {t("table_headers.issue")}
            </label>
            <Select
              className="react-select-field"
              onChange={selectHandlerIssue}
              options={mappedDataIssues}
              value={mappedDataIssues?.find(
                (issue: any) => issue.value === state?.issue?.id
              )}
            />
          </Col>
          <Col md={2}>
            <div className="form-check form-switch p-0">
              <label
                className="d-block text-start"
                htmlFor="flexSwitchCheckDefault"
              >
                {t("global.active")}
              </label>
              <div className="row">
                <input
                  className="form-check-input switch-custom ms-4 mt-2"
                  type="checkbox"
                  name="isActive"
                  checked={state?.isActive}
                  onChange={switchHandler}
                  role="switch"
                  id="flexSwitchCheckDefault"
                />
              </div>
            </div>
          </Col>
          <Col md={2}>
            <div className="form-check form-switch p-0">
              <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
              >
                {t("global.showOnShelf")}
              </label>
              <div className="row">
                <input
                    className="form-check-input switch-custom ms-4 mt-2"
                    type="checkbox"
                    name="showOnShelf"
                    checked={state?.showOnShelf}
                    onChange={switchHandler}
                    role="switch"
                    id="flexSwitchCheckDefault"
                />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="mt-3">
              <label htmlFor="documentFile">{t("global.file")}</label>
              <input
                onChange={(e) => documentHandler(e)}
                className="form-control"
                type="file"
                id="documentFile"
                accept="application/*"
              />
            </div>
            {state?.file && (
              <div className="d-flex align-items-center mt-3">
                <i className="bi bi-file-earmark display-3"></i>
                <a
                  target="_blank"
                  download
                  href={PICTURE_URL + state?.file?.filePath}
                >
                  {state?.file?.fileName}
                </a>
              </div>
            )}
          </Col>

          <div className="mt-3">
            <label htmlFor="imageFile">{t("download.image")}</label>
            <input
              onChange={fileHandler}
              className="form-control"
              type="file"
              accept="image/*"
              id="imageFile"
            />
          </div>
          {state?.image && (
            <div className="col-md-6 py-3">
              <img
                className="rounded"
                alt="200x200"
                width={200}
                src={PICTURE_URL + state?.image.imagePath}
                data-holder-rendered="true"
              />
            </div>
          )}
        </Row>
        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              onClick={() => {
                navigate("/admin/magazine");
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `  ${t("global.update")}` : `${t("global.create")}`}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
