import {CreateForm} from "./CreateForm";
import {useState} from "react";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {useTranslation} from "react-i18next";

import {useNavigate} from "react-router-dom";

import {multipartFormData} from "../../../helpers/api.services";
import {USERS_URL} from "../../../helpers/api.routes";

export const CreateUser = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState<any>({ isActive: true });
  const [image, setImage] = useState(undefined);
  const submitHandler = () => {
    multipartFormData(
      image,
      `${USERS_URL}`,
      JSON.stringify({
        ...state,
      }),
      "post"
    )
      .then((response) => {
        SuccessToast(t(`subscriber.successfully_created`));

        navigate("/admin/subscriber");
      })
      .catch((error) => ErrorToast(error));
  };

  return (
    <div className="col-12">
      <CreateForm
        state={state}
        setState={setState}
        submitHandler={submitHandler}
      />
    </div>
  );
};
