import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { CreateForm } from "./Form";
import { EducationContinuingService } from "../../service";
export const CreateFormContinuing = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [image, setImage] = useState<File>();
  const [document, setDocument] = useState<File>();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [state, setState] = useState<any>({
    title: "",
    isActive: true,
    image: null,
    document: null,
  });

  const submitHandler = (event: any) => {
    const formData = new FormData();
    formData.append("image", image as any);
    formData.append("file", document as any);
    formData.append(
      "body",
      JSON.stringify({ title: state?.title, isActive: state?.isActive })
    );
    EducationContinuingService.create(formData)
      .then((response) => {
        SuccessToast(t(`education.successfully_created`));
        navigate("/admin/education-continuing");
        setState({});
      })
      .catch((error) => ErrorToast(error));
  };
  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler(e);
        }}
      >
        <CreateForm
          setImage={setImage}
          setDocument={setDocument}
          state={state}
          setState={setState}
        />
      </Form>
    </div>
  );
};
