import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ErrorToast, SuccessToast,} from "../../shared/toasters/toasters";
import {AuthService} from "../service";

export interface iForgotPassword {
  email: string;
  repeat_password?: string;
  password?: string;
  code?: string;
}

export const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const [visibilityPassword, setVisibility] = useState(false);
  const [state, setState] = useState({
    email: "",
    securityCode: "",
    password: "",
  });
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const sendEmailForReset = () => {
    AuthService.forgotPasswordChange(state as any)
      .then((res) => {
        SuccessToast(`${t("forgot_password.toasts.successfully_changed_password")}!`);
        navigate("/");
      })
      .catch((error) => ErrorToast(error));
  };
  return (
    <section className="auth-page-wrapper py-5 position-relative d-flex align-items-center justify-content-center min-vh-100 bg-light">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="row g-0">
                  <div className="col-lg-5">
                    <div className="card auth-card bg-primary h-100 border-0 shadow-none p-sm-3 overflow-hidden mb-0">
                      <div className="card-body p-4 d-flex justify-content-between flex-column">
                        <div className="auth-image mb-3">
                          <img
                            src="/assets/images/logo-white.png"
                            alt=""
                            height="26"
                          />
                          <img
                            src="/assets/images/effect-pattern/auth-effect-2.png"
                            alt=""
                            className="auth-effect-2"
                          />
                          <img
                            src="/assets/images/effect-pattern/auth-effect.png"
                            alt=""
                            className="auth-effect"
                          />
                          <img
                            src="/assets/images/effect-pattern/auth-effect.png"
                            alt=""
                            className="auth-effect-3"
                          />
                        </div>

                        <div>
                          <h3 className="text-white">
                            Start your journey with us.
                          </h3>
                          <p className="text-white-75 fs-15">
                            It brings together your tasks, projects, timelines,
                            files and more
                          </p>
                        </div>
                        <div className="text-center text-white-75">
                          <p className="mb-0">
                            &copy;
                            {currentYear} itMedia
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-7">
                    <div className="card mb-0 border-0 shadow-none">
                      <div className="card-body px-0 p-sm-5 m-lg-4">
                        <div className="text-center mt-2">
                          <h5 className="text-primary">
                            {t("forgot_password.restart_password")}
                          </h5>
                        </div>
                        {/* <div
                          className="alert alert-borderless alert-warning text-center mb-2 mx-2"
                          role="alert"
                        >
                          {t("forgot_password.enter_your_email")}
                        </div> */}
                        <div className="p-2 mt-3">
                          <div className="mb-4">
                            <label className="form-label">
                              {t("forgot_password.email")}
                            </label>
                            <input
                              className="form-control"
                              id="email"
                              type={"email"}
                              name={"email"}
                              onChange={changeHandler}
                              //   onKeyDown={(event) =>
                              //     event.key === "Enter" && sendEmailForReset()
                              //   }
                              //   placeholder={`${t("forgot_password.enter")} ${
                              //     queryParams?.code
                              //       ? `${t("forgot_password.password")}`
                              //       : `${t("forgot_password.email")}`
                              //   }`}
                            />
                          </div>
                          <div className="mb-4">
                            <label className="form-label">
                              {t("forgot_password.code")}
                            </label>
                            <input
                              className="form-control"
                              id="securityCode"
                              type={"text"}
                              name={"securityCode"}
                              onChange={changeHandler}
                              //   onKeyDown={(event) =>
                              //     event.key === "Enter" && sendEmailForReset()
                              //   }
                              //   placeholder={`${t("forgot_password.enter")} ${
                              //     queryParams?.code
                              //       ? `${t("forgot_password.password")}`
                              //       : `${t("forgot_password.email")}`
                              //   }`}
                            />
                          </div>

                          <div className="mb-4">
                            <label className="form-label">
                              {t("forgot_password.new_password")}
                            </label>
                            <div className="d-flex align-items-center">
                              <input
                                type={`${
                                  visibilityPassword ? "text" : "password"
                                }`}
                                className="form-control"
                                id="password"
                                name="password"
                                onChange={changeHandler}
                              />
                              {visibilityPassword ? (
                                <i
                                  className="ri-eye-fill align-middle cursor-pointer text-muted"
                                  onClick={() =>
                                    setVisibility(!visibilityPassword)
                                  }
                                  style={{ marginLeft: "-1.5rem" }}
                                />
                              ) : (
                                <i
                                  className="ri-eye-off-fill cursor-pointer text-muted"
                                  onClick={() =>
                                    setVisibility(!visibilityPassword)
                                  }
                                  style={{ marginLeft: "-1.5rem" }}
                                />
                              )}
                            </div>
                          </div>

                          <div className="text-center mt-4">
                            <button
                              className="btn btn-success w-100"
                              onClick={sendEmailForReset}
                            >
                              {t("forgot_password.update_password")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* {!queryParams?.code && (
                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          {`${t("forgot_password.i_remember_my_password")}`}...{" "}
                          <Link
                            to="/"
                            className="fw-semibold text-primary text-decoration-underline"
                          >
                            {`${t("forgot_password.click_here")}`}
                          </Link>
                        </p>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
