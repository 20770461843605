import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ErrorToast } from "../../shared/toasters/toasters";
import { List } from "./components/List";
import { AdminBannerService } from "./service";

interface AdminBannerProps {}

interface BannerItem {
  // Define the properties of a banner item
  // Adjust these based on your actual data structure
  id: number;
  // ... other properties
}

interface BannerListResponse {
  items: BannerItem[];
  totalItems: number;
  perPage: number;
}

const AdminBanner: React.FC<AdminBannerProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [triggerUpdate, setTriggerUpdate] = useState<boolean>(false);
  const [list, setList] = useState<BannerItem[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    term: "",
  });
  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const editHandler = (id: number): void => {
    navigate(`/admin/banner/update/${id}`);
  };

  // const fetchBannerList = async (updatePage: number): Promise<void> => {
  //   try {
  //     const response = await AdminBannerService.getAll({
  //       page: updatePage,
  //       perPage,
  //     });

  //     const { data } = response as { data: BannerListResponse };
  //     setList(data?.items);
  //     setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
  //   } catch (error) {
  //     ErrorToast(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchBannerList(page);
  // }, [triggerUpdate, page]);

  const createNewBanner = (): void => {
    navigate("/admin/banner/create");
  };

  useEffect(() => {
    AdminBannerService.getAll(pagination)
      .then((response) => {
        const { data } = response;
        setList(data?.items);
        setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);
  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-body d-flex align-items-center justify-content-between">
            <div className="search-box me-3">
              <input
                type="text"
                className="form-control"
                id="searchMemberList"
                onChange={(e: any) =>
                  setPagination((prev: any) => ({
                    ...prev,
                    page: 1,
                    term: e.target.value,
                  }))
                }
                placeholder={t("global.search")}
              />
              <i className="ri-search-line search-icon"></i>
            </div>
            <button className="btn btn-primary" onClick={createNewBanner}>
              {t("global.add")}
            </button>
          </div>
        </div>
      </div>

      <div className="col-12">
        <List
          page={page}
          handlePages={handlePages}
          totalPages={totalPages}
          list={list}
          setTriggerUpdate={setTriggerUpdate}
          editHandler={editHandler}
        />
      </div>
    </>
  );
};

export default AdminBanner;
