import {useState} from "react";
import {Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {ConfirmDelete} from "../../../shared/components/ConfirmDelete";
import {EmptyState} from "../../../shared/components/EmptyState";
import {Loader} from "../../../shared/components/Loader";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {useSelector} from "react-redux";
import {AdminFirmCategoryService} from "../service";

export const List = ({
  list,
  setTriggerUpdate,
  editHandler,
}: any) => {
  const domain = useSelector((state: any) => state.company.clientDomain);
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [selectedPrompt, setselectedPrompt] = useState<any>();
  const deleteHandler = () => {
    selectedPrompt?.id &&
    AdminFirmCategoryService.delete(selectedPrompt?.id)
        .then((response) => {
          if (response?.status === "success") {
            SuccessToast(t(`team.successfully_deleted`));
            setTriggerUpdate((prev: boolean) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  return (
    <div className="card">
      <div className="card-header pb-0">
        <div className="d-flex justify-content-between">
          <h4 className="card-title mg-b-0">{t("category.listOfCategorys")}</h4>
        </div>
      </div>
      <div className="card-body">
        {list === undefined ? (
          <Loader />
        ) : list.length === 0 ? (
          <EmptyState />
        ) : (
          <div className="table-responsive">
            <Table className="align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr className="">
                  <th scope="col"> {t("global.id")}</th>
                  <th scope="col"> {t("global.title")}</th>
                  {/* <th scope="col"> {t("global.firstName")}</th>
                  <th scope="col"> {t("global.lastName")}</th>
                  <th scope="col"> {t("global.email")}</th> */}
                  {/* <th scope="col">{t("table_headers.category")}</th> */}
                  <th scope="col">{t("global.active")}</th>
                  <th className="text-center" scope="col">
                    {t("global.actions")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {list.map((prompt: any) => {
                  return (
                    <tr key={prompt.id} className="">
                      <td>
                        <Link to="#" className="fw-medium">
                          #{prompt?.id}
                        </Link>
                      </td>
                      <td>{prompt.title ?? "-"}</td>
                      {/* <td>{prompt.firstName ?? "-"}</td>
                      <td>{prompt.lastName ?? "-"}</td>
                      <td>{prompt.email ?? "-"}</td> */}
                      {/* <td>{prompt.category.name ?? "-"}</td> */}
                      <td
                        className={`text-${
                          prompt.isActive ? "success" : "danger"
                        }`}
                      >
                        {prompt.isActive ? (
                          <span>
                            <i className="ri-checkbox-circle-line fs-17 align-middle" />{" "}
                            {t("global.active")}
                          </span>
                        ) : (
                          <span>
                            <i className="ri-close-circle-line fs-17 align-middle" />
                            {t("global.inactive")}
                          </span>
                        )}
                      </td>
                      <td>
                        <div className="d-flex align-items-center justify-content-center">
                          <button
                            className="btn btn-sm btn-warning d-flex align-items-center me-2"
                            onClick={() => editHandler(prompt.id!)}
                          >
                            <i className="ri-edit-2-fill me-1" />
                            {t("global.edit")}
                          </button>
                          <button
                            className="btn btn-sm btn-danger d-flex align-items-center"
                            onClick={() => {
                              handleShow();
                              setselectedPrompt(prompt);
                            }}
                          >
                            <i className="ri-delete-bin-fill me-1" />
                            {t("global.delete")}
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
        <ConfirmDelete
          show={show}
          setShow={setShow}
          itemName={selectedPrompt?.title}
          deleteHandler={deleteHandler}
          product={selectedPrompt}
          selectedProduct={selectedPrompt}
        />
      </div>
    </div>
  );
};
