import { Table } from "react-bootstrap";
import React, { useState } from "react";
import { Pagination } from "../../../shared/paginations/Paginations";
import { ConfirmDelete } from "../../../shared/components/ConfirmDelete";
import { AdminTestsService } from "../service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { Loader } from "../../../shared/components/Loader";
import { EmptyState } from "../../../shared/components/EmptyState";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const List = ({
  setTriggerNewsList,
  dashboardListNews,
  list,
  totalPages,
  page,
  handlePages,
  setTriggerUpdate,
  editHandler,
}: any) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const domain = useSelector((state: any) => state.company.clientDomain);
  const handleShow = () => setShow(true);
  const [selectedPrompt, setselectedPrompt] = useState<any>();

  const deleteHandler = () => {
    selectedPrompt?.id &&
      AdminTestsService.delete(selectedPrompt?.id)
        .then((response) => {
          if (response?.status === "success") {
            SuccessToast(t(`news.successfully_deleted`));
            setTriggerNewsList && setTriggerNewsList((prev: boolean) => !prev);
            setTriggerUpdate((prev: boolean) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };

  return (
    <div className="card">
      <div className="card-header pb-0">
        <div className="d-flex justify-content-between">
          <h4 className="card-title mg-b-0">
            {dashboardListNews
              ? t("dashboard.latest_ten")
              : t("news.listOfNews")}
          </h4>
        </div>
      </div>
      <div className="card-body">
        {list == undefined ? (
          <Loader />
        ) : list.length === 0 ? (
          <EmptyState />
        ) : (
          <div className="table-responsive">
            <Table className="align-middle table-nowrap mb-0">
              <thead className="table-light ">
                <tr className="">
                  <th scope="col">{t(`global.id`)}</th>
                  <th scope="col">{t(`global.title`)}</th>
                  <th scope="col">{t(`global.active`)}</th>

                  <th className="text-center" scope="col">
                    {t(`table_headers.actions`)}
                  </th>
                </tr>
              </thead>
              <tbody>
                {list?.map((prompt: any) => {
                  return (
                    <tr key={prompt?.id} className="">
                      <td>
                        <a
                          href={domain + "news/" + prompt?.slug}
                          target="_blank"
                          className="fw-medium"
                        >
                          {prompt?.id}
                        </a>
                      </td>
                      <td className="text-start">{prompt?.title ?? "-"}</td>
                      <td
                        className={`text-${
                          prompt?.isActive ? "success" : "danger"
                        }`}
                      >
                        {prompt?.isActive ? (
                          <span>
                            <i className="ri-checkbox-circle-line fs-17 align-middle" />{" "}
                            {t(`global.active`)}
                          </span>
                        ) : (
                          <span>
                            <i className="ri-close-circle-line fs-17 align-middle" />
                            {t(`global.inactive`)}
                          </span>
                        )}
                      </td>

                      <td>
                        <div className="d-flex align-items-center justify-content-center">
                          <a
                            href={domain + "news/" + prompt?.slug}
                            target="_blank"
                            className="btn btn-sm btn-info d-flex align-items-center me-2"
                          >
                            <i className="ri-edit-2-fill me-1" />
                            {t("global.preview")}
                          </a>
                          <button
                            className="btn btn-sm btn-warning d-flex align-items-center me-2"
                            onClick={() => editHandler(prompt?.id)}
                          >
                            <i className="ri-edit-2-fill me-1" />
                            {t("global.edit")}
                          </button>
                          <button
                            className="btn btn-sm btn-danger d-flex align-items-center"
                            onClick={() => {
                              handleShow();
                              setselectedPrompt(prompt);
                            }}
                          >
                            <i className="ri-delete-bin-fill me-1" />
                            {t("global.delete")}
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
        <ConfirmDelete
          show={show}
          setShow={setShow}
          itemName={selectedPrompt?.title}
          deleteHandler={deleteHandler}
          product={selectedPrompt}
          selectedProduct={selectedPrompt}
        />
      </div>
    </div>
  );
};
