import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { CreateForm } from "./CreateForm";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { AdminBrandService } from "../service";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

export const UpdateBrand = () => {
  const [image, setImage] = useState<File>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentBrand, setCurrentBrand] = useState<any>();
  const submitHandler = () => {
    const formData = new FormData();
    formData.append("body", JSON.stringify(currentBrand));
    image && formData.append("image", image);
    AdminBrandService.update(formData, currentBrand.id)
      .then((response) => {
        SuccessToast(t(`brand.successfully_updated`));
        navigate(-1);
        setCurrentBrand({});
      })
      .catch((error) => ErrorToast(error));
  };

  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler();
        }}
      >
        <CreateForm
          state={currentBrand}
          setState={setCurrentBrand}
          image={image}
          setImage={setImage}
        />
      </Form>
    </div>
  );
};
