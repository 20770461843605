// import React from "react";

// export const UpdateFormContinuing = () => {
//   return <div>UpdateForm</div>;
// };
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { CreateForm } from "./Form";
import { EducationContinuingService } from "../../service";
export const UpdateFormContinuing = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [image, setImage] = useState<File>();
  const [document, setDocument] = useState<File>();
  const [state, setState] = useState<any>({
    title: "",
    isActive: true,
    image: {},
    file: {},
  });

  const submitHandler = (event: any) => {
    const formData = new FormData();
    console.log(document);
    image && formData.append("image", image);
    document && formData.append("file", document);
    formData.append(
      "body",
      JSON.stringify({ title: state?.title, isActive: state?.isActive })
    );
    EducationContinuingService.update(formData, Number(id))
      .then((response) => {
        SuccessToast(t(`education.successfully_updated`));
        navigate("/admin/education-continuing");
        setState({});
      })
      .catch((error) => ErrorToast(error));
  };

  return (
    <div className="col-12" id="scrollable-form">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler(e);
        }}
      >
        <CreateForm
          setImage={setImage}
          setDocument={setDocument}
          state={state}
          setState={setState}
        />
      </Form>
    </div>
  );
};
