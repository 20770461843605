import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PICTURE_URL } from "../../../helpers/api.routes";
import { useEffect, useRef, useState } from "react";
import { InitEditor } from "../../tests/components/CreateForm";
import { AdminMediadatenService } from "../service";
import { ErrorToast } from "../../../shared/toasters/toasters";
export const CreateForm = ({
  state,
  setState,
  setDocument,
  triggerUpdate,
}: any) => {
  const { t } = useTranslation();
  const [selectEditor, setSelectEditor] = useState<boolean>(false);
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  const fileHandler = (e: any) => {
    if (e.target.id === "documentFile") {
      setDocument(e.target.files[0]);
    }
  };
  const handleText = (text: string) => {
    setState((prev: any) => ({ ...prev, text }));
  };

  useEffect(() => {
    AdminMediadatenService.getAll()
      .then((response) => {
        const { data } = response;
        InitEditor(data?.text ?? "", handleText);
        setState(data);
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate]);
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={6} className="mb-2">
            <label htmlFor="title" className="required-field">
              {t("global.title")}
            </label>
            <input
              type="text"
              name="title"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.title}
            />
          </Col>
          <Col md={6} className="mb-2">
            <label htmlFor="slug" className="required-field">
              {t("global.slug")}
            </label>
            <input
              type="text"
              name="slug"
              className="form-control"
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.slug}
            />
          </Col>
          <Col md={12} className="mb-2">
            <label htmlFor="title" className="required-field">
              {t("global.text")}
            </label>
            <textarea className="editor" onChange={console.log}></textarea>
            {/* <N1ED
              id={`text`}
              onFocus={(e) => setSelectEditor(true)}
              apiKey={process.env.REACT_APP_EDITOR_KEY}
              initialValue={editorData ? editorData : state ? state?.text : ""}
              onEditorChange={changeEditorHandler}
            /> */}
            {/* <CKEditor
              config={editorConfigurationCustom}
              editor={Editor}
              data={state?.text ?? ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                changeHandlerEditor(data, "text");
              }}
            /> */}
          </Col>

          <Col md={12}>
            <div className="">
              <label htmlFor="documentFile">{t("download.document")}</label>
              <input
                onChange={fileHandler}
                className="form-control"
                type="file"
                id="documentFile"
                accept="application/*"
              />
            </div>
            {state?.document && (
              <div className="d-flex align-items-center mt-3">
                <i className="bi bi-file-earmark display-3"></i>
                <a
                  target="_blank"
                  download
                  href={PICTURE_URL + state.document.documentPath}
                >
                  {state.document.documentName}
                </a>
              </div>
            )}
          </Col>
        </Row>
        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button className="btn btn-primary ms-3" type="submit">
              {t("global.save")}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
