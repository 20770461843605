import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CreateForm } from "./Form";
import { AdminAlternativeDrivesSettingsService } from "../service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
export const CreateFormSettings = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState<any>({
    title: "",
    textFooter: "",
    isActive: true,
  });
  const [imageDe, setImageDe] = useState<File>();
  const [fileDe, setFileDe] = useState<File>();
  const [imageIt, setImageIt] = useState<File>();
  const [fileIt, setFileIt] = useState<File>();
  const [imageFr, setImageFr] = useState<File>();
  const [fileFr, setFileFr] = useState<File>();
  const [sponsorLogo, setSponsorLogo] = useState<File>();
  const submitHandler = (event: any) => {
    const formData = new FormData();
    fileDe && formData.append("fileDE", fileDe ?? state?.files?.DE?.file);
    imageDe && formData.append("imageDE", imageDe ?? state?.files?.DE?.image);
    fileFr && formData.append("fileFR", fileFr ?? state?.files?.FR?.file);
    imageFr && formData.append("imageFR", imageFr ?? state?.files?.FR?.image);
    fileIt && formData.append("fileIT", fileIt ?? state?.files?.IT?.file);
    imageIt && formData.append("imageIT", imageIt ?? state?.files?.IT?.image);
    sponsorLogo &&
      formData.append("sponsorLogo", sponsorLogo ?? state?.sponsorLogo?.image);
    formData.append(
      "body",
      JSON.stringify({
        title: state?.title,
        text: state?.text,
        textFooter: state?.textFooter,
      })
    );
    AdminAlternativeDrivesSettingsService.create(formData)
      .then((response) => {
        SuccessToast(t(`toasts.successfully_created`));
        window.location.reload();
      })
      .catch((error) => ErrorToast(error));
  };
  return (
    <div className="col-12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler(e);
        }}
      >
        <CreateForm
          setFileDe={setFileDe}
          fileDe={fileDe}
          setFileIt={setFileIt}
          fileIt={fileIt}
          setFileFr={setFileFr}
          fileFr={fileFr}
          setImageDe={setImageDe}
          imageDe={imageDe}
          setImageIt={setImageIt}
          imageIt={imageIt}
          setImageFr={setImageFr}
          imageFr={imageFr}
          state={state}
          setState={setState}
          sponsorLogo={sponsorLogo}
          setSponsorLogo={setSponsorLogo}
        />
      </Form>
    </div>
  );
};
