import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AdminPageService } from "../service";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { IForm } from "../interface";
import { InitEditor } from "../../tests/components/CreateForm";

export const CreateForm = ({ state, setState }: IForm) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectEditor, setSelectEditor] = useState<boolean>(false);
  const firstLoad = useRef(true);
  const [editorData, setEditorData] = useState<any>();
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  const handleText = (text: string) => {
    setState((prev) => ({ ...prev, text }));
  };
  useEffect(() => {
    if (!!Number(id)) {
      AdminPageService.getSingle(id as unknown as number)
        .then((response) => {
          const { data } = response;
          setState(data);
          window.scrollTo(0, 0);
          InitEditor(data?.text, handleText);
        })
        .catch((error) => ErrorToast(error));
    } else {
      InitEditor(undefined, handleText);
    }
  }, [id]);
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={4} className="mb-3">
            <label htmlFor="title" className="required-field">
              {t("global.title")}
            </label>
            <input
              type="text"
              name="title"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.title}
            />
          </Col>
          <Col md={12} className="mb-2">
            <label htmlFor="text" className="required-field">
              {t("global.text")}
            </label>
            <textarea className="editor" onChange={console.log}></textarea>
          </Col>
        </Row>
        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              onClick={() => {
                navigate("/admin/page");
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `${t("global.update")}` : `${t("global.create")}`}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
