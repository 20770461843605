import React, { useEffect, useState } from "react";
import { ErrorToast } from "../../shared/toasters/toasters";
import { AdminAlbumService } from "../album/service";
import { List } from "./components/List";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AdminVideoService } from "./service";

export const AdminGalleryVideo = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    term: "",
  });
  const [list, setList] = useState(undefined);

  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const editHandler = (id: number) => {
    navigate(`/admin/video-update/${id}`);
  };

  useEffect(() => {
    AdminVideoService.getAll(pagination)
      .then((response) => {
        const { data } = response;
        setList(data?.items);
        setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-body d-flex align-items-center justify-content-between">
            <div className="search-box me-3">
              <input
                type="text"
                className="form-control"
                id="searchMemberList"
                onChange={(e: any) =>
                  setPagination((prev: any) => ({
                    ...prev,
                    page: 1,
                    term: e.target.value,
                  }))
                }
                placeholder={t("global.search")}
              />
              <i className="ri-search-line search-icon"></i>
            </div>
            <button
              className="btn btn-primary"
              onClick={() => navigate("/admin/gallery/video-create")}
            >
              {t("global.add")}
            </button>
          </div>
        </div>
      </div>
      <div className="col-12">
        <List
          page={page}
          handlePages={handlePages}
          totalPages={totalPages}
          list={list}
          setTriggerUpdate={setTriggerUpdate}
          editHandler={editHandler}
        />
      </div>
    </>
  );
};
