import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { PICTURE_URL } from "../../../helpers/api.routes";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { AdminModelService } from "../service";
import { ISelect } from "../../../shared/interface";
// import {N1ED} from "@edsdk/n1ed-react";
// import {Editor} from "tinymce";
import { ModelGalleryUpdate } from "./ModelGalleryUpdate";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import { editorConfigurationCustom } from "../../../shared/functions/editorConfigurationCustom";
// @ts-ignore
// import Editor from "ckeditor5-custom-build/build/ckeditor";
export const CreateForm = ({
  state,
  brands,
  modelCats,
  setState,
  setPhotoAlbums,
  setImage,
}: any) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectEditor, setSelectEditor] = useState<boolean>(false);
  const firstLoad = useRef(true);
  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  const selectHandler = (e: ISelect | null, name: string) => {
    setState((prev: any) => ({ ...prev, [name]: e!.value }));
  };
  // const changeEditorHandler = (content: string, editor: Editor) => {
  //   setState((prev: any) => ({ ...prev, [editor?.id]: content }));
  //   firstLoad.current = false;
  // };
  const changeHandlerEditor = (value: string, eventName: string) => {
    setState((prev: any) => ({ ...prev, [eventName]: value }));
  };
  const switchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  const mappedDataBrands = brands?.map((brand: any) => ({
    label: brand.name,
    value: brand.id,
  }));
  const mappedDataBrandCats = modelCats?.map((cat: any) => ({
    label: cat.name,
    value: cat.id,
  }));
  const fileHandler = (e: any) => {
    setImage(e.target.files[0]);
  };
  const handleText = (text: string) => {
    setState((prev: any) => ({ ...prev, text }));
  };
  const handleTextDescription = (description: string) => {
    setState((prev: any) => ({ ...prev, description }));
  };
  const handleTextSpecification = (specification: string) => {
    setState((prev: any) => ({ ...prev, specification }));
  };
  useEffect(() => {
    if (!!Number(id)) {
      AdminModelService.getSingle(id as unknown as number)
        .then((response) => {
          const { data } = response;
          InitEditorMoreFields(
            data?.description,
            handleTextDescription,
            ".description"
          );
          InitEditorMoreFields(data?.text, handleText, ".text");
          InitEditorMoreFields(
            data?.specification,
            handleTextSpecification,
            ".specification"
          );
          setState({
            ...data,
            brand: data.brand.id,
            modelCategory: data.modelCategory.id,
          });
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error));
    } else {
      InitEditorMoreFields(undefined, handleTextDescription, ".description");
      InitEditorMoreFields(undefined, handleText, ".text");
      InitEditorMoreFields(
        undefined,
        handleTextSpecification,
        ".specification"
      );
    }
  }, [id]);

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={6} className="mb-3">
            <label htmlFor="title" className="required-field">
              {t("global.title")}
            </label>
            <input
              type="text"
              name="title"
              className="form-control"
              required
              onChange={changeHandler}
              value={state?.id && state?.title}
            />
          </Col>
          <Col md={6}>
            <label htmlFor="brand" className="required-field">
              {t("brand.brand")}
            </label>
            <Select
              className="react-select-field"
              onChange={(e) => selectHandler(e, "brand")}
              options={mappedDataBrands}
              value={mappedDataBrands?.find(
                (category: any) => category.value === state?.brand
              )}
            />
          </Col>
          <Col md={6}>
            <label htmlFor="model_cat" className="required-field">
              {t("model.model_cat")}
            </label>
            <Select
              className="react-select-field"
              onChange={(e) => selectHandler(e, "modelCategory")}
              options={mappedDataBrandCats}
              value={mappedDataBrandCats?.find(
                (category: any) => category.value === state?.modelCategory
              )}
            />
          </Col>
          <Col md={2} className="">
            <div className="form-check form-switch p-0">
              <label
                className="d-block text-start"
                htmlFor="flexSwitchCheckDefault"
              >
                {t("global.active")}
              </label>
              <div className="row">
                <input
                  className="form-check-input switch-custom ms-4 mt-2"
                  type="checkbox"
                  name={id ? "active" : "isActive"}
                  checked={id ? state?.active : state?.isActive}
                  onChange={switchHandler}
                  role="switch"
                  id="flexSwitchCheckDefault"
                />
              </div>
            </div>
          </Col>
          <Col md={12} className="mt-2">
            <label className="d-block text-start" htmlFor="text">
              {t("global.text")}
            </label>
            <textarea className="editor text" onChange={console.log}></textarea>
            {/* <N1ED
              id={`text`}
              onFocus={(e) => setSelectEditor(true)}
              apiKey={process.env.REACT_APP_EDITOR_KEY}
              value={state?.text ?? ""}
              onEditorChange={changeEditorHandler}
            /> */}
            {/* <CKEditor
              config={editorConfigurationCustom}
              editor={Editor}
              data={state?.text ?? ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                changeHandlerEditor(data, "text");
              }}
            /> */}
          </Col>
          <Col md={12} className="mt-2">
            <label className="d-block text-start" htmlFor="description">
              {t("global.description")}
            </label>
            <textarea
              className="editor description"
              onChange={console.log}
            ></textarea>
            {
              /* <N1ED
              id={`description`}
              onFocus={(e) => setSelectEditor(true)}
              apiKey={process.env.REACT_APP_EDITOR_KEY}
              // initialValue={
              //   editorData ? editorData : state ? state?.description : ""
              // }
              value={state?.description ?? ""}
              // initialValue={
              //   editorData ? editorData : state ? state?.description : ""
              // }
              onEditorChange={changeEditorHandler}
            /> */
              // <CKEditor
              //   config={editorConfigurationCustom}
              //   editor={Editor}
              //   data={state?.description ?? ""}
              //   onChange={(event, editor) => {
              //     const data = editor.getData();
              //     changeHandlerEditor(data, "description");
              //   }}
              // />
            }
          </Col>
          <Col md={12} className="mt-2">
            <label className="d-block text-start" htmlFor="specification">
              {t("global.specification")}
            </label>
            <textarea
              className="editor specification"
              onChange={console.log}
            ></textarea>
            {/* <N1ED
              id={`specification`}
              onFocus={(e) => setSelectEditor(true)}
              apiKey={process.env.REACT_APP_EDITOR_KEY}
              // initialValue={
              //   editorData ? editorData : state ? state?.specification : ""
              // }
              value={state?.specification ?? ""}
              // initialValue={
              //   editorData
              //     ? editorData
              //     : state
              //       ? (state as any)?.specification
              //       : ""
              // }
              onEditorChange={changeEditorHandler}
            /> */}
            {/* <CKEditor
              config={editorConfigurationCustom}
              editor={Editor}
              data={state?.specification ?? ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                changeHandlerEditor(data, "specification");
              }}
            /> */}
          </Col>
          <Col md={12} className="mt-2">
            <div>
              <label htmlFor="imageFile"> {t("download.image")}</label>
              <input
                onChange={fileHandler}
                className="form-control"
                type="file"
                accept="image/*"
                id="imageFile"
              />
            </div>
            {state?.image && (
              <img
                className="mt-3 rounded show-img-form"
                alt="200x200"
                src={PICTURE_URL + state?.image.path}
                data-holder-rendered="true"
              />
            )}
          </Col>
          <Col md={12} className="my-3">
            <div className="">
              <label htmlFor="photoGallery">{t("album.photo_gallery")}</label>
              <input
                onChange={(e) => setPhotoAlbums(e.target.files)}
                multiple={true}
                name={"image"}
                className="form-control"
                type="file"
                accept="image/*"
                id="photoGallery"
              />
            </div>
          </Col>
          {state?.id && <ModelGalleryUpdate />}
        </Row>
        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              onClick={() => {
                navigate("/admin/model");
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `${t("global.update")}` : `${t("global.create")}`}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
export function removeElementsByClass(className: string) {
  const elements = document.getElementsByClassName(className);
  while (elements.length > 0) {
    elements && elements[0]?.parentNode?.removeChild(elements && elements[0]);
  }
}

export function InitEditorMoreFields(
  value: string = "",
  cb: (value: string) => void,
  className: string
) {
  (window as any).tinymce.init({
    selector: className,
    apiKey: "a5kCyOAV",
    width: "100%",
    height: 500,
    sidebar: undefined,
    menubar: "file edit view insert format tools table tc help",
    plugins:
      "image link file-manager print code preview importcss searchreplace autolink autosave save directionality  visualblocks visualchars fullscreen image link media  template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists  wordcount imagetools textpattern noneditable help   charmap  quickbars emoticons  ",
    statusbar: true,
    toolbar:
      "Upload undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist  | forecolor backcolor  removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media  template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment code",
    extended_valid_elements: "i[class]",
    init_instance_callback: (editor: any) => {
      editor.on("input", (e: any) => {
        cb(e.target.innerHTML);
      });
      editor.on("focus", (e: any) => {
        removeElementsByClass(
          "jsplus_theme jsplus_ui_full_screen jsplus_ui_sidebar_widgets jsplus_show"
        );
      });
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === "childList") {
            cb(editor.getContent());
          }
        }
      });
      const editorElement = editor.getContainer();
      observer.observe(editorElement, { childList: true, subtree: true });
    },
    setup: (editor: any) => {
      editor.on("init", () => {
        editor.setContent(value);
      });
    },
  });
}
