import React, { useEffect, useState } from "react";

import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { AdminFeaturedArticlesService } from "./service";

import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import { AdminNewsService } from "../news/service";

export const AdminFeaturedArticles = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState<any>();
  const domain = useSelector((state: any) => state.company.clientDomain);
  const [pagination, setPagination] = useState({
    term: "",
  });
  const [list, setList] = useState([]);
  const handleShow = () => setShow(true);
  const editHandler = (id: number): void => {
    navigate(`/admin/news/update/${id}`);
  };

  const deleteHandler = () => {
    selectedPrompt?.id &&
      AdminNewsService.updateFeatured(selectedPrompt?.id)
        .then((response) => {
          if (response?.status === "success") {
            SuccessToast(t(`news.successfully_deleted`));
            setTriggerUpdate((prev: boolean) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  const updatePosition = (newList: any) => {
    const newListUpdated = newList?.map((item: any) => item.id);
    AdminFeaturedArticlesService.updatePosition({ ids: newListUpdated });
  };
  useEffect(() => {
    AdminFeaturedArticlesService.getAll()
      .then((response) => {
        const { data } = response;
        setList(data);
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate]);

  return (
    <>
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body d-flex align-items-center">
            {/* <div className="search-box">
              <input
                type="text"
                className="form-control"
                id="searchMemberList"
                onChange={(e: any) =>
                  setPagination((prev: any) => ({
                    ...prev,
                    page: 1,
                    term: e.target.value,
                  }))
                }
                placeholder={t("global.search")}
              />
              <i className="ri-search-line search-icon"></i>
            </div> */}
            <div className="col-sm-auto ms-auto">
              <div className="list-grid-nav hstack gap-1">
                <button
                  className="btn btn-info"
                  onClick={() => navigate("/admin/news/create")}
                >
                  {t("global.add")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12">
        <DraggableBootstrapTable
          list={list}
          setList={setList}
          headers={["id", "title"]}
          title="featuredArticle"
          onEdit={editHandler}
          selectedPrompt={selectedPrompt}
          setSelectedPrompt={setSelectedPrompt}
          handleShow={handleShow}
          show={show}
          setShow={setShow}
          deleteHandler={deleteHandler}
          updatePosition={updatePosition}
        />
      </div>
    </>
  );
};
