export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_MODE
    : process.env.REACT_APP_PRO_MODE;
export const PICTURE_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_MODE?.slice(0, -5)
    : process.env.REACT_APP_PRO_MODE?.slice(0, -5);
export const LOGIN_URL = BASE_URL + "login_check";
export const BRAND_URL = BASE_URL + "admin/brand";
export const MODEL_CATEGORY_URL = BASE_URL + "admin/model-category";
export const FIRM_CATEGORY_URL = BASE_URL + "admin/firm-category";
export const FIRM_URL = BASE_URL + "admin/firm";
export const FIRM_GALLERY_URL = BASE_URL + "admin/firm-gallery";
export const EMPLOYEE_URL = BASE_URL + "admin/employee";
export const TEAM_URL = BASE_URL + "admin/provider-team";
export const TEAM_CATEGORY_URL = BASE_URL + "admin/team-category";
export const ADMIN_URL = BASE_URL + "admin/user/admins";
export const SUBSCRIPTION_URL = BASE_URL + "admin/subscription";
export const BANNER_URL = BASE_URL + "admin/banner";
export const MENU_POSITION = BASE_URL + "admin/menu-position";
export const NEWS_THEME_URL = BASE_URL + "admin/news-theme";
export const NEWSLETTER_URL = BASE_URL + "admin/newsletter";
export const NEWSLETTER_ERROR_URL = BASE_URL + "admin/email/newsletter";
export const NEWSLETTER_SUBSCRIBER_URL =
  BASE_URL + "admin/newsletter-subscriber";
export const TEST_MAIL_URL = BASE_URL + "admin/newsletter-test-subscriber";
export const SETTINGS_URL = BASE_URL + "admin/settings";
export const SITE_SETTINGS = BASE_URL + "admin/site-settings";
export const MEDIADATEN = BASE_URL + "admin/mediadaten";
export const MENU_URL = BASE_URL + "admin/menu";
export const USER_URL = BASE_URL + "me";
export const USERS_URL = BASE_URL + "admin/user";

export const CATEGORY_URL = BASE_URL + "admin/news/category";
export const CATEGORY_DOWNLOAD_URL = BASE_URL + "admin/download-category";
export const COMPANY_DETAILS_URL = BASE_URL + "admin/company";
export const DOWNLOAD_URL = BASE_URL + "admin/download";
export const EDUCATION_CONTINUING_URL = BASE_URL + "admin/continuing-education";
export const EDUCATION_CONTINUING_SETTINGS_URL =
  BASE_URL + "admin/continuing-education-settings";
export const PAGE_URL = BASE_URL + "admin/page";
export const MARKET_URL = BASE_URL + "admin/market";
export const FLEET_URL = BASE_URL + "admin/fleet";
export const EVENT_CATEGORY_URL = BASE_URL + "admin/event-category";
export const EVENT_URL = BASE_URL + "admin/event";
export const PRODUCT_URL = BASE_URL + "admin/product";
export const PRODUCT_CATEGORY_URL = BASE_URL + "admin/product-category";
export const ALTERNATIVE_DRIVES_SETTINGS_URL =
  BASE_URL + "admin/alternative-drives-settings";
export const ALTERNATIVE_DRIVES_URL = BASE_URL + "admin/alternative-drives";
export const ALTERNATIVE_DRIVES_CATEGORY_URL =
  BASE_URL + "admin/alternative-drives-category";
export const EVENT_SUMMARY_CAT_URL = BASE_URL + "admin/event-summary/category";
export const EVENT_SUMMARY_URL = BASE_URL + "admin/event-summary";
export const MODEL_URL = BASE_URL + "admin/model";
export const MAGAZINE_CATEGORY_URL = BASE_URL + "admin/magazine-category";
export const MAGAZINE_ISSUE_URL = BASE_URL + "admin/magazine-issue";
export const MAGAZINE_URL = BASE_URL + "admin/magazine";
export const ALBUM_URL = BASE_URL + "admin/album";
export const GALLERY_URL = BASE_URL + "admin/gallery-image";
export const MODEL_GALLERY_URL = BASE_URL + "admin/model-gallery";
export const VIDEO_URL = BASE_URL + "admin/video";
export const NEWS_URL = BASE_URL + "admin/news";
export const TESTS_URL = BASE_URL + "admin/tests-news";
export const DASHOARD_URL = BASE_URL + "admin/dashboard";
export const RESET_PASSWORD_URL = BASE_URL + "admin/security/forgot-password";
