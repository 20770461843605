import { requests } from "../helpers/api.services";
import { ILogin } from "./login/Login";
import { LOGIN_URL, RESET_PASSWORD_URL } from "../helpers/api.routes";
import { iForgotPassword } from "./login/ForgotPassword";

export const AuthService = {
  login: (body: ILogin) => requests.post(LOGIN_URL, body),
  forgotPasswordRequest: (body: iForgotPassword) =>
    requests.post(`${RESET_PASSWORD_URL}/request`, body),
  forgotPasswordChange: (body: iForgotPassword) =>
    requests.post(`${RESET_PASSWORD_URL}/change`, body),
};
