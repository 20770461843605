import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AdminNewsletterEmailService } from "../service";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import Select from "react-select";

export const CreateForm = ({ state, setState }: any) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { greeting } = useSelector((state: RootState) => state.enum);
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  const mappedData = greeting?.map((item: string) => ({
    label: `${t(`greeting.${item?.toLowerCase()}`)}`,
    value: item,
  }));
  const switchHandler = (e: any) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  const selectHandler = (e: any) => {
    setState((prev: any) => ({ ...prev, greeting: e.value }));
  };
  useEffect(() => {
    if (!!Number(id)) {
      AdminNewsletterEmailService.getSingle(+id!)
        .then((response) => {
          const { data } = response;
          setState(data);
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error));
    }
  }, [id]);
  return (
    <>
      {" "}
      <Card>
        <Card.Body>
          <Row>
            <Col md={6} className="mb-3">
              <label htmlFor="title">{t("global.title")}</label>
              <input
                type="text"
                name="title"
                className="form-control"
                onChange={changeHandler}
                value={state && state.title}
              />
            </Col>

            <Col md={6} className="mb-3">
              <label htmlFor="company">{t("global.company")}</label>
              <input
                type="text"
                name="company"
                className="form-control"
                onChange={changeHandler}
                value={state && state.company}
              />
            </Col>

            <Col md={6} className="mb-3">
              <label htmlFor="greeting">{t("global.greeting")}</label>
              <Select
                className="react-select-field"
                value={mappedData?.find(
                  (item: any) => item?.value === state?.greeting
                )}
                onChange={selectHandler}
                options={mappedData}
              />
            </Col>

            <Col md={6} className="mb-3">
              <label htmlFor="firstName" className="required-field-news">
                {t("global.firstName")}
              </label>
              <input
                type="text"
                name="firstName"
                className="form-control"
                required
                onChange={changeHandler}
                value={state && state.firstName}
              />
            </Col>

            <Col md={6} className="mb-3">
              <label htmlFor="lastName" className="required-field-news">
                {t("global.lastName")}
              </label>
              <input
                type="text"
                name="lastName"
                className="form-control"
                required
                onChange={changeHandler}
                value={state && state.lastName}
              />
            </Col>

            <Col md={6} className="mb-3">
              <label htmlFor="titleAfter">{t("global.titleAfter")}</label>
              <input
                type="text"
                name="titleAfter"
                className="form-control"
                onChange={changeHandler}
                value={state && state.titleAfter}
              />
            </Col>

            <Col md={6} className="mb-3">
              <label htmlFor="street">{t("global.street")}</label>
              <input
                type="text"
                name="street"
                className="form-control"
                onChange={changeHandler}
                value={state && state.street}
              />
            </Col>

            <Col md={6} className="mb-3">
              <label htmlFor="plz">{t("global.plz")}</label>
              <input
                type="text"
                name="plz"
                className="form-control"
                onChange={changeHandler}
                value={state && state.plz}
              />
            </Col>

            <Col md={6} className="mb-3">
              <label htmlFor="location">{t("global.location")}</label>
              <input
                type="text"
                name="location"
                className="form-control"
                onChange={changeHandler}
                value={state && state.location}
              />
            </Col>

            <Col md={6} className="mb-3">
              <label htmlFor="phone">{t("global.phone")}</label>
              <input
                type="text"
                name="phone"
                className="form-control"
                onChange={changeHandler}
                value={state && state.phone}
              />
            </Col>

            <Col md={6} className="mb-3">
              <label htmlFor="email" className="required-field-news">
                {t("global.email")}
              </label>
              <input
                type="text"
                name="email"
                className="form-control"
                required
                onChange={changeHandler}
                value={state && state.email}
              />
            </Col>
            <Col md={4} className="">
              <div className="form-check form-switch p-0">
                <label
                  className="d-block text-start"
                  htmlFor="flexSwitchCheckDefault"
                >
                  {t("global.active")}
                </label>
                <div className="row">
                  <input
                    className="form-check-input switch-custom ms-4 mt-2"
                    type="checkbox"
                    name="active"
                    checked={state?.active}
                    onChange={switchHandler}
                    role="switch"
                    id="flexSwitchCheckDefault"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <div className="col-12 my-2 d-flex justify-content-end text-align-center">
            <div>
              <button
                className="btn btn-info"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}
              >
                {t("global.cancel")}
              </button>
              <button className="btn btn-primary ms-3" type="submit">
                {state?.id
                  ? `  ${t("global.update")}`
                  : `${t("global.create")}`}
              </button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
