import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { AdminAlternativeDrivesCategoryService } from "./service";
import { useTranslation } from "react-i18next";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import { CreateForm } from "./components/CreateForm";
import Select from "react-select";
import { themeSelect } from "../../shared/components/Select";
import { SingleValue } from "react-select";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Pagination } from "../../shared/paginations/Paginations";
import { List } from "./components/List";

export const AdminAlternativeDrivesCategory = () => {
  const { t } = useTranslation();
  const { language } = useSelector((state: RootState) => state.enum);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [selectedPrompt, setSelectedPrompt] = useState<any>();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [state, setState] = useState<any>({ isActive: false });
  const [list, setList] = useState<any>(undefined);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 0,
    language: null,
  });
  const updatePosition = (newList: any) => {
    const newListUpdated = newList?.map((item: any) => item.id);
    AdminAlternativeDrivesCategoryService.updatePosition({
      ids: newListUpdated,
    });
  };

  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const deleteHandler = () => {
    // selectedPrompt?.id &&
    //   AdminAlternativeDrivesCategoryService.delete(selectedPrompt?.id)
    //     .then((response) => {
    //       if (response?.status === "success") {
    //         SuccessToast(t(`event_cat.successfully_deleted`));
    //         setTriggerUpdate((prev: boolean) => !prev);
    //       }
    //     })
    //     .catch((error) => ErrorToast(error));
  };

  const editHandler = (brandId: number) => {
    setIsMenuOpened(true);
    AdminAlternativeDrivesCategoryService.getSingle(brandId)
      .then((response) => {
        const { data } = response;
        setState(data);
        window.scrollTo(0, 0);
      })
      .catch((error) => ErrorToast(error));
  };
  const handleSelectLanguage = (
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) => {
    if (selectedSingle?.value as string) {
      setPagination({
        ...pagination,
        page: 1,
        perPage: 0,
        language: selectedSingle?.value as string,
      } as any);
      setTriggerUpdate(!triggerUpdate);
    } else {
      setPagination({
        ...pagination,
        page: 1,
        perPage: 0,
        language: null,
      } as any);
      setTriggerUpdate(!triggerUpdate);
    }
  };
  const submitHandler = () => {
    if (state.id) {
      AdminAlternativeDrivesCategoryService.update(state, state.id)
        .then((response) => {
          SuccessToast(t(`event_cat.successfully_updated`));
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
          setState({});
        })
        .catch((error) => ErrorToast(error));
    } else {
      AdminAlternativeDrivesCategoryService.create({
        ...state,
        language: state?.language?.toUpperCase(),
      })
        .then((response) => {
          SuccessToast(t(`event_cat.successfully_created`));
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
          setState({});
        })
        .catch((error) => ErrorToast(error));
    }
  };
  useEffect(() => {
    AdminAlternativeDrivesCategoryService.getAll(pagination)
      .then((response) => {
        const { data } = response;
        setList(data?.items);
        // setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
        // setPage(data?.page);
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate]);
  return (
    <>
      {!isMenuOpened && (
        <div className="col-12">
          <div className="card">
            <div className="card-body d-flex align-items-center justify-content-between">
              {/* <h4 className="card-title mg-b-0">{t("alternative_drives.add")}</h4> */}
              <div style={{ width: "200px" }} className="nesto">
                <Select
                  isClearable={true}
                  theme={themeSelect}
                  placeholder={t("global.language")}
                  defaultValue={
                    pagination?.language && {
                      label: `${t(`languages.${(pagination?.language as string)?.toLowerCase()}`)}`,
                      value: pagination?.language,
                    }
                  }
                  options={language?.map((item: string) => ({
                    label: `${t(`languages.${item?.toLowerCase()}`)}`,
                    value: item,
                  }))}
                  onChange={(e) => {
                    handleSelectLanguage(
                      e as SingleValue<{ label: string; value: string }>
                    );
                  }}
                />
              </div>
              <button
                className="btn btn-primary"
                onClick={() => setIsMenuOpened(true)}
              >
                {t("global.add")}
              </button>
            </div>
          </div>
        </div>
      )}

      {isMenuOpened && (
        <div className="col-12">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              submitHandler();
            }}
          >
            <CreateForm
              setIsMenuOpened={setIsMenuOpened}
              state={state}
              setState={setState}
            />
          </Form>
        </div>
      )}
      {/* {pagination?.language ? (
        <div className="col-12">
          <List
            page={page}
            handlePages={handlePages}
            totalPages={totalPages}
            list={list!}
            setTriggerUpdate={setTriggerUpdate}
            editHandler={editHandler}
            // domain={domain}
          />
        </div>
      ) : 
      ( */}
      <div className="col-12">
        <DraggableBootstrapTable
          list={list}
          setList={setList}
          headers={["id", "title", "language", "color", "isActive"]}
          title="alternativeDriveCat"
          onEdit={editHandler}
          selectedPrompt={selectedPrompt}
          setSelectedPrompt={setSelectedPrompt}
          handleShow={handleShow}
          show={show}
          setShow={setShow}
          deleteHandler={deleteHandler}
          updatePosition={updatePosition}
          preview={true}
          removeDeleteBtn={true}
        />
      </div>
      {/* )} */}
    </>
  );
};
