import {requests} from "../../helpers/api.services";
import {TEAM_CATEGORY_URL} from "../../helpers/api.routes";

export const AdminTeamCategoryService = {
    getAll: (params: any) => requests.get(`${TEAM_CATEGORY_URL}`, params),
    create: (params: any) => requests.post(`${TEAM_CATEGORY_URL}`, params),
    updatePosition: (params: any) => requests.patch(`${TEAM_CATEGORY_URL}`, params),
    getSingle: (id: number) => requests.get(`${TEAM_CATEGORY_URL}/${id}`),
    update: (id: number, params: any) => requests.put(`${TEAM_CATEGORY_URL}/${id}`, params),
    delete: (id: number) => requests.delete(`${TEAM_CATEGORY_URL}/${id}`)
};