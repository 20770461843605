import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// import { N1ED } from "@edsdk/n1ed-react";
// import { Editor } from "tinymce";
import "../../../../assets/scss/EditorStyle.css";
import { EducationContinuingService } from "../../service";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import { editorConfigurationCustom } from "../../../../shared/functions/editorConfigurationCustom";
import { InitEditorMoreFields } from "../../../model/components/CreateForm";
// @ts-ignore
// import Editor from "ckeditor5-custom-build/build/ckeditor";
interface IState {
  title: string;
  textFooter: string;
  text: string;
}
export const ContinuingEducationSettings = () => {
  const [state, setState] = useState<IState>({
    title: "",
    textFooter: "",
    text: "",
  });
  const { t } = useTranslation();
  const [count, setCount] = useState({});
  const [editorData, setEditorData] = useState<any>();
  const firstLoad = useRef(true);
  const [selectEditor, setSelectEditor] = useState<boolean>(false);
  const [image, setImage] = useState<File>();
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  // const changeEditorHandler = (content: string, editor: Editor) => {
  //   setState((prev: any) => ({ ...prev, [editor?.id]: content }));
  //   if (!firstLoad.current) {
  //     setEditorData(editor);
  //   }
  //   firstLoad.current = false;
  // };
  const changeHandlerEditor = (value: string, eventName: string) => {
    setState((prev: any) => ({ ...prev, [eventName]: value }));
  };
  const handleText = (text: string) => {
    setState((prev: any) => ({ ...prev, text }));
  };
  const handleTextFooter = (textFooter: string) => {
    setState((prev: any) => ({ ...prev, textFooter }));
  };
  const submitHandler = (e: any) => {
    e.preventDefault();
    // const formData = new FormData();
    // formData.append("body", JSON.stringify(state));
    // image && formData.append("image", image);
    EducationContinuingService.createSettings(state)
      .then((response) => {
        SuccessToast(t(`toasts.successfully_created`));
      })
      .catch((error) => ErrorToast(error));
  };
  useEffect(() => {
    EducationContinuingService.getAllSettings()
      .then((response) => {
        const { data } = response;
        setState(data);
        InitEditorMoreFields(data?.text ?? undefined, handleText, ".text");
        InitEditorMoreFields(
          data?.textFooter ?? undefined,
          handleTextFooter,
          ".textFooter"
        );
      })
      .catch((error) => ErrorToast(error));
  }, []);
  function removeElementsByClass(className: string) {
    const elements = document.getElementsByClassName(className);
    while (elements.length > 0) {
      elements && elements[0]?.parentNode?.removeChild(elements[0]);
    }
    setSelectEditor(false);
  }

  useEffect(() => {
    if (selectEditor) {
      removeElementsByClass(
        "jsplus_theme jsplus_ui_full_screen jsplus_ui_sidebar_widgets jsplus_show"
      );
    }
  }, [selectEditor]);
  return (
    <div className="card">
      <div className="card-body">
        <Row>
          <Col md={6} className="mb-2">
            <label htmlFor="title" className="required-field">
              {t("global.title")}
            </label>
            <input
              type="text"
              name="title"
              className="form-control"
              required
              onChange={(e) => changeHandler(e as any)}
              value={state?.title}
            />
          </Col>
          <Col md={12} className="mb-3" id="custom-editor">
            <div>
              <label htmlFor="title" className="required-field">
                {t("global.text")}
              </label>
            </div>
            <textarea className="editor text" onChange={console.log}></textarea>
            {/* <N1ED
              id={`text`}
              onFocus={(e) => setSelectEditor(true)}
              apiKey={process.env.REACT_APP_EDITOR_KEY}
              value={state?.text ?? ""}
              onEditorChange={changeEditorHandler}
            /> */}
            {/* <CKEditor
              config={editorConfigurationCustom}
              editor={Editor}
              data={state?.text ?? ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                changeHandlerEditor(data, "text");
              }}
            /> */}
          </Col>
          <Col md={12} className="mb-3" id="custom-editor">
            <label htmlFor="title" className="required-field">
              {t("newsletter.footer")}
            </label>
            <textarea
              className="editor textFooter"
              onChange={console.log}
            ></textarea>
            {/* <N1ED
              id={`textFooter`}
              onFocus={(e) => setSelectEditor(true)}
              apiKey={process.env.REACT_APP_EDITOR_KEY}
              // initialValue={
              value={state?.textFooter ?? ""}
              // initialValue={editorData ? editorData : state?.textFooter ?? ""}
              //   editorData ? editorData : state ? state?.textFooter : ""
              // }
              onEditorChange={changeEditorHandler}
            /> */}
            {/* <CKEditor
              config={editorConfigurationCustom}
              editor={Editor}
              data={state?.textFooter ?? ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                changeHandlerEditor(data, "textFooter");
              }}
            /> */}
          </Col>
        </Row>
        <Row>
          <div className="col-12 mt-4 d-flex justify-content-end text-align-center">
            <div>
              <button
                className="btn btn-primary"
                onClick={(e) => submitHandler(e)}
              >
                {t("global.save")}
              </button>
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
};
