import {
  EDUCATION_CONTINUING_URL,
  EDUCATION_CONTINUING_SETTINGS_URL,
} from "../../helpers/api.routes";
import { requests } from "../../helpers/api.services";

export const EducationContinuingService = {
  // params: any
  getAll: () =>
    requests.get(
      `${EDUCATION_CONTINUING_URL}`
      // , params
    ),
  create: (params: any) =>
    requests.post(`${EDUCATION_CONTINUING_URL}`, params, true),
  getSingle: (id: number) => requests.get(`${EDUCATION_CONTINUING_URL}/${id}`),
  update: (params: any, id: number) =>
    requests.post(`${EDUCATION_CONTINUING_URL}/${id}`, params, true),
  delete: (id: number) => requests.delete(`${EDUCATION_CONTINUING_URL}/${id}`),
  deleteImg: (id: number) =>
    requests.delete(`${EDUCATION_CONTINUING_URL}/file/${id}`),
  getAllSettings: () => requests.get(`${EDUCATION_CONTINUING_SETTINGS_URL}`),
  createSettings: (params: any) =>
    requests.post(`${EDUCATION_CONTINUING_SETTINGS_URL}`, params, true),
};
