import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { useTranslation } from "react-i18next";
import { CreateForm } from "./CreateForm";
import "../../../assets/scss/scroll.scss";
import {
  DocImgFormData,
  multipartFormData,
} from "../../../helpers/api.services";
import { ALTERNATIVE_DRIVES_URL } from "../../../helpers/api.routes";
import { AdminAlternativeDrivesCategoryService } from "../../alternativeDrivesCategory/service";

export const UpdateAlternativeDrives = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(false);
  const [additionalFiles, setAdditionalFiles] = useState<any[]>();
  const [categories, setCategories] = useState();
  const [document, setDocument] = useState<File>();
  const [state, setState] = useState<any>({
    title: "",
    date: "",
    isActive: true,
    url: "",
    mainImg: {},
    embeddedCode: "",
  });
  const [image, setImage] = useState<File>();
  const submitHandler = (event: any) => {
    DocImgFormData(
      document ?? state?.document,
      image ?? state?.image,
      `${ALTERNATIVE_DRIVES_URL}/${id}`,
      JSON.stringify({
        ...state,
        isActive: state?.isActive ?? false,
      }),
      "post"
    )
      // multipartFormData(
      //   image,
      //   `${ALTERNATIVE_DRIVES_URL}/${id}`,
      //   JSON.stringify({ ...state, isActive: state?.isActive ?? false }),
      //   "post"
      // )
      .then((response) => {
        SuccessToast(t(`event.successfully_created`));
        navigate("/admin/alternative-drives");
      })
      .catch((error) => ErrorToast(error));
  };
  useEffect(() => {
    AdminAlternativeDrivesCategoryService.getAll({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setCategories(data?.items);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <div className="col-12" id="scrollable-form">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler(e);
        }}
      >
        <CreateForm
          state={state}
          setState={setState}
          setImage={setImage}
          setDocument={setDocument}
          categories={categories}
        />
      </Form>
    </div>
  );
};
